import { BorderedIcon } from "@swan-io/lake/src/components/BorderedIcon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { SwanLogo } from "@swan-io/lake/src/components/SwanLogo";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { backgroundColor, colors, spacings } from "@swan-io/lake/src/constants/design";
import { useCallback, useEffect, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { TrackPressable } from "../components/TrackPressable";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";

const styles = StyleSheet.create({
  base: {
    backgroundColor: backgroundColor.default,
    flexGrow: 1,
  },
  contentContainer: {
    flexGrow: 1,
    paddingVertical: 40,
    justifyContent: "center",
  },
  logo: {
    height: 24,
    width: "initial",
    margin: "auto",
  },
  content: {
    margin: "auto",
  },
  tile: {
    margin: "auto",
    paddingHorizontal: spacings[72],
    paddingVertical: spacings[72],
    width: 430,
  },
  iconContainer: {
    margin: "auto",
  },
  link: {
    wordBreak: "keep-all",
  },
  underline: {
    textDecorationLine: "underline",
  },
});

const HelpLink = ({ to, children }: { to: string; children: string }) => (
  <LakeText variant="smallRegular" style={styles.link}>
    <Link to={to} style={({ hovered }) => hovered && styles.underline} target="blank">
      {children}
    </Link>
  </LakeText>
);

type LoginContentProps = {
  onLogin: () => void;
};

const LoginContent = ({ onLogin }: LoginContentProps) => {
  return (
    <>
      <View style={styles.iconContainer}>
        <BorderedIcon name="lake-building-bank" size={100} padding={8} color="live" />
      </View>

      <Space height={32} />

      <TrackPressable action="Login">
        <LakeButton color="live" onPress={onLogin}>
          {t("login.buttonText")}
        </LakeButton>
      </TrackPressable>

      <Separator space={32} />

      <LakeText color={colors.gray[900]} variant="semibold">
        {t("login.needHelp")}
      </LakeText>

      <Space height={8} />
      <HelpLink to="https://docs.swan.io/">{t("login.linkDocumentation")}</HelpLink>
    </>
  );
};

type Props = {
  redirectTo?: string;
  sessionExpired?: boolean;
};

export const LoginPage = ({ redirectTo, sessionExpired = false }: Props) => {
  // we store initial sessionExpired value, then remove it from the url
  const sessionExpiredWarningVisible = useRef(sessionExpired).current;

  useEffect(() => {
    Router.replace("BaseLogin");
  }, []);

  const handleButtonPress = useCallback(() => {
    const queryString = new URLSearchParams();
    if (redirectTo != null) {
      queryString.append("redirectTo", redirectTo);
    }
    window.location.assign(`/auth/login?${queryString.toString()}`);
  }, [redirectTo]);

  return (
    <ScrollView style={styles.base} contentContainerStyle={styles.contentContainer}>
      <View role="banner">
        <SwanLogo style={styles.logo} />
      </View>

      <View style={styles.content}>
        {sessionExpiredWarningVisible && (
          <>
            <Space height={24} />
            <LakeAlert variant="warning" title={t("login.sessionExpired.title")} />
          </>
        )}

        <Space height={24} />

        <Tile style={styles.tile}>
          <LoginContent onLogin={handleButtonPress} />
        </Tile>

        <Space height={24} />
      </View>
    </ScrollView>
  );
};
