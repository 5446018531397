import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, CopyableTextCell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Toggle } from "@swan-io/lake/src/components/Toggle";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import {
  AccountMerchantProfilesDocument,
  MerchantProfileFiltersInput,
  MerchantProfileFragment,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { RouteParams, Router } from "../utils/routes";
import { AccountDetailMerchantProfileRequestWizard } from "./AccountDetailMerchantProfileRequestWizard";
import { ColumnChooser, useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";

const styles = StyleSheet.create({
  endFilters: {
    flexGrow: 0,
    flexShrink: 1,
  },
});

type Props = {
  accountId: string;
  params: RouteParams<"AccountDetailMerchantList">;
};

const PER_PAGE = 20;

type ExtraInfo = undefined;

const defaultFixedColumns: ColumnConfig<MerchantProfileFragment, ExtraInfo>[] = [
  {
    id: "merchantName",
    width: 350,
    title: t("merchantProfile.list.merchantName"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => <TextCell text={item.merchantName} />,
  },
];
const defaultActiveColumns: ColumnConfig<MerchantProfileFragment, ExtraInfo>[] = [
  {
    id: "merchantId",
    width: 400,
    title: t("merchantProfile.list.merchantId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => (
      <CopyableTextCell
        text={item.id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    id: "updatedAt",
    width: 200,
    title: t("merchantProfile.list.updatedAt"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => (
      <TextCell text={dayjs(item.updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
  {
    id: "status",
    width: 200,
    title: t("merchantProfile.list.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => (
      <Cell>
        {match(item.statusInfo.status)
          .with("Enabled", () => <Tag color="positive">{t("merchantProfile.status.enabled")}</Tag>)
          .with("PendingReview", () => (
            <Tag color="shakespear">{t("merchantProfile.status.pendingReview")}</Tag>
          ))
          .with("Rejected", () => (
            <Tag color="negative">{t("merchantProfile.status.rejected")}</Tag>
          ))
          .with("Suspended", () => (
            <Tag color="warning">{t("merchantProfile.status.suspended")}</Tag>
          ))
          .with("Disabled", () => <Tag color="gray">{t("merchantProfile.status.disabled")}</Tag>)
          .otherwise(() => null)}
      </Cell>
    ),
  },
];

export const AccountDetailMerchantList = ({ accountId, params }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const filters = useMemo((): MerchantProfileFiltersInput => {
    const { status = "Active" } = params;

    return {
      status: match(status)
        .returnType<MerchantProfileFiltersInput["status"]>()
        .with("Active", () => ["Enabled", "PendingReview"])
        .with("Inactive", () => ["Disabled", "Rejected", "Suspended"])
        .exhaustive(),
    } as const;
  }, [params]);

  const [data, { isLoading, setVariables, reload }] = useQuery(AccountMerchantProfilesDocument, {
    first: PER_PAGE,
    accountId,
    filters,
  });

  const columns = useColumnChooser("Account>Merchants", {
    defaultActiveColumns,
    defaultFixedColumns,
  });

  const merchantProfiles = data.mapOkToResult(data =>
    Option.fromNullable(data.account?.merchantProfiles).toResult(undefined),
  );

  const getRowLink = useCallback(
    ({ item }: LinkConfig<MerchantProfileFragment, ExtraInfo>) => (
      <Link
        to={Router.AccountDetailMerchantProfileRoot({
          accountId,
          projectEnv,
          projectId,
          merchantProfileId: item.id,
        })}
      />
    ),
    [projectEnv, projectId, accountId],
  );

  return (
    <>
      <Box direction="row" alignItems="center">
        <LakeButton
          size="small"
          color="current"
          icon="add-circle-filled"
          onPress={event => {
            event.preventDefault();
            event.stopPropagation();

            Router.push("AccountDetailMerchantList", {
              ...params,
              new: "true",
            });
          }}
        >
          {t("merchant.new")}
        </LakeButton>

        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        <LakeButton
          ariaLabel={t("common.refresh")}
          mode="secondary"
          size="small"
          icon="arrow-counterclockwise-filled"
          onPress={() => {
            reload();
          }}
        />

        <Fill minWidth={16} />

        <Box direction="row" alignItems="center" justifyContent="end" style={styles.endFilters}>
          <Toggle
            value={params.status === "Active" || params.status === undefined}
            onToggle={status =>
              Router.push("AccountDetailMerchantList", {
                accountId,
                projectEnv,
                projectId,
                status: status ? "Active" : "Inactive",
              })
            }
            onLabel={t("merchantProfile.list.Active")}
            offLabel={t("merchantProfile.list.Inactive")}
          />
        </Box>
      </Box>

      {match(merchantProfiles)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <>
            <>
              <Space height={16} />

              <Connection connection={data}>
                {merchantProfiles => (
                  <VirtualizedList
                    variant="default"
                    stickedToStartColumns={columns.fixed}
                    marginHorizontal={negativeSpacings[24]}
                    data={merchantProfiles?.edges.map(({ node }) => node) ?? []}
                    keyExtractor={item => item.id}
                    extraInfo={undefined}
                    columns={columns.active}
                    headerHeight={48}
                    rowHeight={48}
                    onEndReached={() => {
                      if (merchantProfiles?.pageInfo.hasNextPage === true) {
                        setVariables({ after: merchantProfiles.pageInfo.endCursor });
                      }
                    }}
                    loading={{ isLoading, count: PER_PAGE }}
                    renderEmptyList={() => (
                      <EmptyView
                        icon="lake-merchant"
                        borderedIcon={true}
                        title={t("merchantProfile.list.noResults")}
                      />
                    )}
                    getRowLink={getRowLink}
                  />
                )}
              </Connection>
            </>

            <LakeModal
              visible={params.new === "true"}
              title={t("merchantProfile.request.title")}
              icon="add-circle-regular"
              maxWidth={850}
            >
              <AccountDetailMerchantProfileRequestWizard
                accountId={accountId}
                onPressClose={() =>
                  Router.push("AccountDetailMerchantList", {
                    accountId,
                    projectEnv: params.projectEnv,
                    projectId: params.projectId,
                  })
                }
              />
            </LakeModal>
          </>
        ))
        .exhaustive()}
    </>
  );
};
