import { Array, AsyncData, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  LinkConfig,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import {
  emptyToUndefined,
  isNotNullish,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  filter,
  FiltersStack,
  FiltersState,
  useFiltersProps,
} from "@swan-io/shared-business/src/components/Filters";
import { useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import { match, P } from "ts-pattern";
import {
  GetCardTransactionsDocument,
  TransactionListFragment,
  TransactionStatus,
  TransactionTypeEnum,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";
import { transactionTypes } from "../utils/transactions";
import {
  isAfterUpdatedAtSelectable,
  isBeforeUpdatedAtSelectable,
  validateAfterUpdatedAt,
  validateBeforeUpdatedAt,
} from "../utils/validations";
import {
  AccountDetailTransactionList,
  accountDetailTransactionListDefaultColumns,
  ExtraInfo,
} from "./AccountDetailTransactionList";
import { useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

type Props = {
  cardId: string;
  params: RouteParams<"CardDetailTransactions">;
};

const statuses = ["Booked", "Canceled", "Pending", "Rejected", "Released", "Upcoming"] as const;

const filtersDefinition = {
  isAfterUpdatedAt: filter.date({
    label: t("transaction.filters.isAfterUpdatedAt.label"),
    isSelectable: isAfterUpdatedAtSelectable,
    validate: validateAfterUpdatedAt,
  }),
  isBeforeUpdatedAt: filter.date({
    label: t("transaction.filters.isBeforeUpdatedAt.label"),
    isSelectable: isBeforeUpdatedAtSelectable,
    validate: validateBeforeUpdatedAt,
  }),
  status: filter.checkbox<TransactionStatus>({
    label: t("transaction.filters.status.label"),
    items: statuses.map(value => ({ label: value, value })),
  }),
  type: filter.checkbox<TransactionTypeEnum>({
    label: t("transaction.filters.type.label"),
    items: transactionTypes.array
      .filter(item => item.startsWith("Card"))
      .map(value => ({ label: value, value })),
  }),
};

type TransactionsFilters = FiltersState<typeof filtersDefinition>;

type FiltersFormProps = {
  filters: TransactionsFilters;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: TransactionsFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
};

const FiltersForm = ({
  filters,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
}: FiltersFormProps) => {
  const filtersProps = useFiltersProps({ filtersDefinition, filters });
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <View>
      <Box direction="row" alignItems="center">
        <FilterChooser {...filtersProps.chooser} />
        <Space width={16} />

        <TrackPressable action="Refresh card transactions list">
          <LakeButton
            ariaLabel={t("webhook.table.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />
      <FiltersStack {...filtersProps.stack} onChangeFilters={onChangeFilters} />
    </View>
  );
};

const PER_PAGE = 20;

export const CardDetailsTransactions = ({ cardId, params }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();

  const filters = useMemo((): TransactionsFilters => {
    const { status, type } = params;

    return {
      isAfterUpdatedAt: params.isAfterUpdatedAt,
      isBeforeUpdatedAt: params.isBeforeUpdatedAt,
      status: isNotNullish(status)
        ? Array.filterMap(status, item =>
            match(item)
              .with(...statuses, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,

      type: isNotNullish(type)
        ? Array.filterMap(type, item =>
            transactionTypes.is(item) && item.startsWith("Card")
              ? Option.Some(item)
              : Option.None(),
          )
        : undefined,
    } as const;
  }, [params]);

  const search = nullishOrEmptyToUndefined(params.search?.trim());
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetCardTransactionsDocument, {
    cardId,
    first: PER_PAGE,
    filters: { ...filters, search },
  });

  const accountId = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(data => data.card?.accountMembership.account?.id)
    .toUndefined();

  const columns = useColumnChooser("Card>Transactions", {
    defaultFixedColumns: accountDetailTransactionListDefaultColumns.fixed,
    defaultActiveColumns: accountDetailTransactionListDefaultColumns.active,
  });

  const extraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      reexecuteQuery: () => {},
    };
  }, [projectEnv, projectId]);

  const getRowLink = useCallback(
    ({
      item: {
        node: { id },
      },
      extraInfo: { projectEnv, projectId },
    }: LinkConfig<GetEdge<TransactionListFragment>, ExtraInfo>) =>
      accountId == null ? undefined : (
        <Link
          to={Router.AccountDetailTransactionsDetail({
            projectId,
            projectEnv,
            accountId,
            transactionId: id,
          })}
        />
      ),
    [accountId],
  );

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ card }) => Option.fromNullable(card?.transactions?.totalCount));

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <FiltersForm
        filters={filters}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("CardDetailTransactions", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("CardDetailTransactions", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={PER_PAGE}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.card?.transactions}>
            {transactions => (
              <AccountDetailTransactionList
                transactions={transactions?.edges ?? []}
                columns={columns}
                onEndReached={() => {
                  if (transactions?.pageInfo.hasNextPage === true) {
                    setVariables({ after: transactions?.pageInfo.endCursor ?? undefined });
                  }
                }}
                emptyListTitle={t("card.transactions.empty")}
                isLoading={isLoading}
                perPage={PER_PAGE}
                extraInfo={extraInfo}
                getRowLink={getRowLink}
                hasSearchOrFilters={hasSearchOrFilters}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
