import { Box } from "@swan-io/lake/src/components/Box";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Popover } from "@swan-io/lake/src/components/Popover";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { colors } from "@swan-io/lake/src/constants/design";
import { useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import {
  AccountHolderVerificationStatusInfoFragment,
  AccountStatus,
  ConsentStatus,
} from "../graphql/partner";
import { t } from "../utils/i18n";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  list: {
    maxWidth: 500,
  },
});

type VerificationStatusTagProps = {
  verificationStatusInfo: AccountHolderVerificationStatusInfoFragment;
};

type AccountStatusTagProps = {
  accountStatus: AccountStatus;
};

export const VerificationStatusTag = ({ verificationStatusInfo }: VerificationStatusTagProps) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const buttonRef = useRef<View>(null);

  return match(verificationStatusInfo)
    .with({ __typename: "AccountHolderNotStartedVerificationStatusInfo" }, () => (
      <Tag color="gray">{t("accountHolder.verificationStatus.notStarted")}</Tag>
    ))
    .with({ __typename: "AccountHolderPendingVerificationStatusInfo" }, () => (
      <Tag color="shakespear">{t("accountHolder.verificationStatus.pending")}</Tag>
    ))
    .with({ __typename: "AccountHolderVerifiedVerificationStatusInfo" }, () => (
      <Tag color="positive">{t("accountHolder.verificationStatus.verified")}</Tag>
    ))
    .with({ __typename: "AccountHolderRefusedVerificationStatusInfo" }, () => (
      <Tag color="negative">{t("accountHolder.verificationStatus.refused")}</Tag>
    ))
    .with(
      { __typename: "AccountHolderWaitingForInformationVerificationStatusInfo" },
      ({ verificationRequirements }) => (
        <Box direction="row" alignItems="center">
          <Tag color="warning">{t("accountHolder.verificationStatus.waitingForInformation")}</Tag>

          {verificationRequirements.length > 0 ? (
            <>
              <Popover
                referenceRef={buttonRef}
                visible={isInfoOpen}
                onDismiss={() => setIsInfoOpen(false)}
              >
                <LakeAlert
                  variant="warning"
                  title={t("accountHolder.verificationStatus.moreInformationRequired")}
                  callToAction={
                    <LakeButton
                      onPress={() => setIsInfoOpen(false)}
                      icon="lake-close"
                      mode="tertiary"
                      size="small"
                      color="warning"
                      ariaLabel={t("common.close")}
                    />
                  }
                >
                  <View role="list" style={styles.list}>
                    {verificationRequirements.map((verificationRequirement, index) => (
                      <View role="listitem" key={index}>
                        <LakeText color={colors.gray[700]} variant="smallRegular">
                          {"• "}
                          {match(verificationRequirement.type)
                            .with("FirstTransferRequired", () =>
                              t(
                                "accountHolder.verificationStatus.moreInformationRequired.FirstTransferRequired",
                              ),
                            )
                            .with("LegalRepresentativeDetailsRequired", () =>
                              t(
                                "accountHolder.verificationStatus.moreInformationRequired.LegalRepresentativeDetailsRequired",
                              ),
                            )
                            .with("OrganizationDetailsRequired", () =>
                              t(
                                "accountHolder.verificationStatus.moreInformationRequired.OrganizationDetailsRequired",
                              ),
                            )
                            .with("SupportingDocumentsRequired", () =>
                              t(
                                "accountHolder.verificationStatus.moreInformationRequired.SupportingDocumentsRequired",
                              ),
                            )
                            .with("TaxIdRequired", () =>
                              t(
                                "accountHolder.verificationStatus.moreInformationRequired.TaxIdRequired",
                              ),
                            )
                            .with("UboDetailsRequired", () =>
                              t(
                                "accountHolder.verificationStatus.moreInformationRequired.UboDetailsRequired",
                              ),
                            )
                            .with("Other", () =>
                              t("accountHolder.verificationStatus.moreInformationRequired.Other"),
                            )
                            .exhaustive()}
                        </LakeText>
                      </View>
                    ))}
                  </View>
                </LakeAlert>
              </Popover>

              <Space width={8} />

              <TrackPressable
                action="Open Account Holder verification requirements popover"
                ref={buttonRef}
              >
                <LakeButton
                  mode="tertiary"
                  size="small"
                  icon="info-regular"
                  ariaLabel={t("common.moreInfo")}
                  onPress={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setIsInfoOpen(true);
                  }}
                />
              </TrackPressable>
            </>
          ) : null}
        </Box>
      ),
    )
    .exhaustive();
};

export const AccountStatusTag = ({ accountStatus }: AccountStatusTagProps) => {
  return match(accountStatus)
    .with("Opened", () => <Tag color="positive">{accountStatus}</Tag>)
    .with("Suspended", () => <Tag color="warning">{accountStatus}</Tag>)
    .with("Closing", () => <Tag color="shakespear">{accountStatus}</Tag>)
    .with("Closed", () => <Tag color="negative">{accountStatus}</Tag>)
    .exhaustive();
};

type ConsentStatusTagProps = {
  status: ConsentStatus;
};

export const ConsentStatusTag = ({ status }: ConsentStatusTagProps) => {
  return match(status)
    .with("Accepted", () => <Tag color="positive">{t("consentStatus.accepted")}</Tag>)
    .with("Canceled", () => <Tag color="gray">{t("consentStatus.canceled")}</Tag>)
    .with("CredentialRefused", () => (
      <Tag color="negative">{t("consentStatus.credentialRefused")}</Tag>
    ))
    .with("CustomerRefused", () => <Tag color="negative">{t("consentStatus.customerRefused")}</Tag>)
    .with("Expired", () => <Tag color="negative">{t("consentStatus.expired")}</Tag>)
    .with("Failed", () => <Tag color="negative">{t("consentStatus.failed")}</Tag>)
    .with("Started", () => <Tag color="sunglow">{t("consentStatus.started")}</Tag>)
    .with("OperationCommitting", () => (
      <Tag color="gray">{t("consentStatus.operationCommitting")}</Tag>
    ))
    .with("Created", () => <Tag color="shakespear">{t("consentStatus.created")}</Tag>)
    .exhaustive();
};
