import { Array, AsyncData, Future, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  LinkConfig,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { identity } from "@swan-io/lake/src/utils/function";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import {
  emptyToUndefined,
  isNotNullish,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import {
  filter,
  FiltersStack,
  FiltersState,
  useFiltersProps,
} from "@swan-io/shared-business/src/components/Filters";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useMemo, useState } from "react";
import { match, P } from "ts-pattern";
import { CardList, cardListDefaultColumns } from "../components/CardList";
import { CancelContractModal } from "../components/CardModals";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "../components/ColumnChooser";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import { OrderByDirection } from "../graphql/admin";
import {
  CardOrderByFieldInput,
  CardOrderByInput,
  CardStatus,
  CardType,
  ExportCardDataDocument,
  GetCardPageDocument,
} from "../graphql/partner";
import { usePermissions } from "../hooks/usePermissions";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { Card, Edge, getCardHolderName } from "../utils/card";
import { formatCount, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";

type ExtraInfo = {
  projectId: string;
  projectEnv: ProjectEnv;
  onCancelCard: (card: Card) => void;
  reexecuteQuery: () => void;
  onChangeSort?: (sortBy: CardOrderByInput) => void;
  sortBy?: CardOrderByInput;
  canCancelCardContracts: boolean;
};

const getRowLink = ({
  item: {
    node: { id },
  },
  extraInfo: { projectEnv, projectId },
}: LinkConfig<Edge, ExtraInfo>) => (
  <Link to={Router.CardDetailRoot({ projectId, projectEnv, cardId: id })} />
);

type Props = {
  params: RouteParams<"CardRoot">;
  membershipEmail: string | undefined;
};

const PER_PAGE = 20;

const statusList = ["Canceled", "Canceling", "ConsentPending", "Enabled", "Processing"] as const;
const typeList = ["SingleUseVirtual", "Virtual", "VirtualAndPhysical"] as const;

export const CardListPage = ({ params, membershipEmail }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();
  const canCancelCardContracts = usePermissions(projectEnv).dataCard.write;

  const [exportDataVisible, { open: openExportDataModal, close: closeExportDataModal }] =
    useDisclosure(false);

  const [exportAccountData, accountDataExported] = useMutation(ExportCardDataDocument);

  const onSubmit = () => {
    if (isNotNullish(membershipEmail)) {
      exportAccountData({
        input: {
          email: membershipEmail,
          filters,
        },
      })
        .mapOkToResult(data => Option.fromNullable(data.exportCardData).toResult("No data"))
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(() => {
          closeExportDataModal();
          showToast({ variant: "success", title: t("acountStatements.dataExported") });
        })
        .tapError(error => {
          showToast({ variant: "error", title: translateError(error), error });
        });
    }
  };

  const sortBy: CardOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<CardOrderByFieldInput>()
        .with("createdAt", "updatedAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const filters = useMemo((): CardFilters => {
    const { statuses, types } = params;

    return {
      statuses: isNotNullish(statuses)
        ? Array.filterMap(statuses, item =>
            match(item)
              .with(...statusList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      types: isNotNullish(types)
        ? Array.filterMap(types, item =>
            match(item)
              .with(...typeList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
    } as const;
  }, [params]);

  const search = nullishOrEmptyToUndefined(params.search?.trim());
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetCardPageDocument, {
    first: PER_PAGE,
    filters: { ...filters, search },
    orderBy: sortBy,
  });

  const [cancelCardInfo, setCancelCardInfo] = useState<Card | null>(null);

  const columns = useColumnChooser("Cards", {
    defaultFixedColumns: cardListDefaultColumns.fixed,
    defaultActiveColumns: cardListDefaultColumns.active,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      reexecuteQuery: reload,
      onCancelCard: card => {
        setCancelCardInfo(card);
      },
      onChangeSort: ({ field, direction }) => {
        Router.push("CardRoot", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
      canCancelCardContracts,
    };
  }, [projectEnv, projectId, canCancelCardContracts, reload, sortBy, params]);

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ cards }) => cards.totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <FiltersForm
        membershipEmail={membershipEmail}
        openExportDataModal={openExportDataModal}
        filters={filters}
        columns={columns}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("CardRoot", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("CardRoot", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.cards}>
            {cards => (
              <>
                <CardList
                  cards={cards.edges}
                  columns={columns}
                  onEndReached={() => {
                    if (cards.pageInfo.hasNextPage === true) {
                      setVariables({ after: cards.pageInfo.endCursor ?? undefined });
                    }
                  }}
                  isLoading={isLoading}
                  perPage={PER_PAGE}
                  emptyListTitle={t("cards.list.emptyTitle")}
                  extraInfo={extraInfo}
                  getRowLink={getRowLink}
                  hasSearchOrFilters={hasSearchOrFilters}
                />

                <LakeModal
                  icon="arrow-download-filled"
                  visible={exportDataVisible}
                  onPressClose={closeExportDataModal}
                  title={t("dataExport.exportData")}
                >
                  <LakeText color={colors.gray[900]}>
                    {cards.totalCount > 100_000
                      ? t("dataExport.exportData.largeExportDescription")
                      : t("dataExport.exportData.smallExportDescription")}
                  </LakeText>

                  <LakeButtonGroup paddingBottom={0}>
                    <TrackPressable action="Export account data">
                      <LakeButton
                        color="current"
                        grow={true}
                        onPress={onSubmit}
                        loading={accountDataExported.isLoading()}
                      >
                        {t("dataExport.exportData.confirm")}
                      </LakeButton>
                    </TrackPressable>
                  </LakeButtonGroup>
                </LakeModal>
              </>
            )}
          </Connection>
        ))
        .exhaustive()}

      <CancelContractModal
        visible={isNotNullish(cancelCardInfo)}
        cardId={cancelCardInfo?.id ?? ""}
        cardHolderName={getCardHolderName(cancelCardInfo).getOr("")}
        onClose={() => {
          setCancelCardInfo(null);
        }}
        onSuccess={() => {
          reload();
        }}
        canCancelCardContracts={canCancelCardContracts}
      />
    </>
  );
};

const filtersDefinition = {
  types: filter.checkbox<CardType>({
    label: t("cards.list.type"),
    items: [
      { value: "SingleUseVirtual", label: t("card.type.singleUseVirtual") },
      { value: "Virtual", label: t("card.type.virtual") },
      { value: "VirtualAndPhysical", label: t("card.type.virtualAndPhysical") },
    ],
  }),
  statuses: filter.checkbox<CardStatus>({
    label: t("cards.list.status"),
    items: [
      { value: "Canceled", label: t("cards.list.status.canceled") },
      { value: "Canceling", label: t("cards.list.status.canceling") },
      { value: "Enabled", label: t("cards.list.status.enabled") },
      { value: "ConsentPending", label: t("cards.list.status.consentPending") },
      { value: "Processing", label: t("cards.list.status.processing") },
    ],
  }),
};

type CardFilters = FiltersState<typeof filtersDefinition>;

type FiltersFormProps = {
  filters: CardFilters;
  columns: ColumnChooserConfig<Edge, ExtraInfo>;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: CardFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
  membershipEmail: string | undefined;
  openExportDataModal: () => void;
};

const FiltersForm = ({
  filters,
  columns,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
  openExportDataModal,
  membershipEmail,
}: FiltersFormProps) => {
  const filtersProps = useFiltersProps({ filtersDefinition, filters });
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser {...filtersProps.chooser} />
        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        {isNotNullish(membershipEmail) && (
          <>
            <LakeTooltip placement="center" content={t("dataExport.exportData")}>
              <TrackPressable action="Export user data">
                <LakeButton
                  mode="secondary"
                  size="small"
                  onPress={openExportDataModal}
                  icon="arrow-download-filled"
                  ariaLabel={t("dataExport.exportData")}
                />
              </TrackPressable>
            </LakeTooltip>

            <Space width={8} />
          </>
        )}

        <TrackPressable action="Refresh cards list">
          <LakeButton
            ariaLabel={t("webhook.table.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />
      <FiltersStack {...filtersProps.stack} onChangeFilters={onChangeFilters} />
    </>
  );
};
