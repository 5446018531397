import { Future, Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  Cell,
  CopyableTextCell,
  HeaderCell,
  LinkCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { stubFalse, stubTrue } from "@swan-io/lake/src/utils/function";
import { emptyToUndefined, isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  filter,
  FiltersStack,
  FiltersState,
  useFiltersProps,
} from "@swan-io/shared-business/src/components/Filters";
import dayjs from "dayjs";
import { ReactElement, useState } from "react";
import { match } from "ts-pattern";
import {
  AccountMembershipListFragment,
  AccountMembershipOrderByInput,
  AccountMembershipStatus,
} from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { formatCount, locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { BooleanTag } from "./BooleanTag";
import { ColumnChooser, ColumnChooserConfig } from "./ColumnChooser";
import { TrackPressable } from "./TrackPressable";

const statusList = [
  "BindingUserError",
  "ConsentPending",
  "Disabled",
  "Enabled",
  "InvitationSent",
  "Suspended",
] as const;

const booleanFilterItems = [
  { value: undefined, label: t("common.filters.all") },
  { value: true, label: t("common.true") },
  { value: false, label: t("common.false") },
];

const filtersDefinition = {
  status: filter.checkbox<AccountMembershipStatus>({
    label: t("accountMembership.status"),
    items: statusList.map(value => ({ value, label: value })),
  }),
  canViewAccount: filter.radio({
    label: t("accountMembership.canViewAccount"),
    items: booleanFilterItems,
  }),
  canInitiatePayments: filter.radio({
    label: t("accountMembership.canInitiatePayments"),
    items: booleanFilterItems,
  }),
  canManageAccountMembership: filter.radio({
    label: t("accountMembership.canManageAccountMembership"),
    items: booleanFilterItems,
  }),
  canManageBeneficiaries: filter.radio({
    label: t("accountMembership.canManageBeneficiaries"),
    items: booleanFilterItems,
  }),
  canManageCards: filter.radio({
    label: t("accountMembership.canManageCards"),
    items: booleanFilterItems,
  }),
};

export type AccountMembershipsFilters = FiltersState<typeof filtersDefinition>;

export const parseBooleanParam = (value: string | undefined) =>
  match(value)
    .with("true", stubTrue)
    .with("false", stubFalse)
    .otherwise(() => undefined);

type FiltersFormProps = {
  filters: AccountMembershipsFilters;
  columns: ColumnChooserConfig<Edge, ExtraInfo>;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: AccountMembershipsFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
};

export const AccountMembershipsFiltersForm = ({
  filters,
  columns,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
}: FiltersFormProps) => {
  const filtersProps = useFiltersProps({ filtersDefinition, filters });
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser {...filtersProps.chooser} />
        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        <TrackPressable action="Refresh membership filters list">
          <LakeButton
            ariaLabel={t("webhook.table.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />
      <FiltersStack {...filtersProps.stack} onChangeFilters={onChangeFilters} />
    </>
  );
};

type Edge = GetEdge<AccountMembershipListFragment>;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: AccountMembershipOrderByInput) => void;
  sortBy?: AccountMembershipOrderByInput;
  reexecuteQuery: () => void;
};

const keyExtractor = ({ node: { id } }: Edge) => id;

const defaultFixedColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 240,
    id: "email",
    title: t("accountMembership.email"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { email },
      },
    }) => <TextCell variant="medium" text={email} />,
  },
];

const defaultActiveColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 200,
    id: "user",
    title: t("accountMembership.user"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { user },
      },
    }) =>
      isNotNullish(user) && isNotNullish(user.fullName) ? <TextCell text={user.fullName} /> : null,
  },
  {
    width: 250,
    id: "account",
    title: t("accountMembership.account"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { account },
      },
      extraInfo: { projectId, projectEnv },
    }) => {
      if (isNotNullish(account)) {
        const accountId = account.id;

        return (
          <LinkCell
            onPress={() => Router.push("AccountDetailRoot", { projectId, projectEnv, accountId })}
          >
            {`${account.name} - ${account.number}`}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 250,
    id: "accountHolder",
    title: t("accountMembership.accountHolder"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { account },
      },
      extraInfo: { projectId, projectEnv },
    }) => {
      if (isNotNullish(account)) {
        const accountHolderId = account.holder.id;

        return (
          <LinkCell
            onPress={() =>
              Router.push("HoldersDetailRoot", {
                projectId,
                projectEnv,
                accountHolderId,
              })
            }
          >
            {account.holder.info.name}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 160,
    id: "status",
    title: t("accountMembership.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          statusInfo: { status },
        },
      },
    }) => (
      <Cell>
        {match(status)
          .with("ConsentPending", () => (
            <Tag color="gray">{t("accountMembership.status.consentPending")}</Tag>
          ))
          .with("InvitationSent", () => (
            <Tag color="shakespear">{t("accountMembership.status.invitationSent")}</Tag>
          ))
          .with("BindingUserError", () => (
            <Tag color="negative">{t("accountMembership.status.bindingUserError")}</Tag>
          ))
          .with("Enabled", () => (
            <Tag color="positive">{t("accountMembership.status.enabled")}</Tag>
          ))
          .with("Suspended", () => (
            <Tag color="warning">{t("accountMembership.status.suspended")}</Tag>
          ))
          .with("Disabled", () => <Tag color="gray">{t("accountMembership.status.disabled")}</Tag>)
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 200,
    id: "id",
    title: t("accountMembership.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 190,
    id: "legalRepresentative",
    title: t("accountMembership.legalRepresentative"),
    renderTitle: ({ title }) => <HeaderCell align="center" text={title} />,
    renderCell: ({
      item: {
        node: { legalRepresentative },
      },
    }) => (
      <Cell align="center">
        <BooleanTag value={legalRepresentative} />
      </Cell>
    ),
  },
  {
    width: 170,
    id: "canViewAccount",
    title: t("accountMembership.canViewAccount"),
    renderTitle: ({ title }) => <HeaderCell align="center" text={title} />,
    renderCell: ({
      item: {
        node: { canViewAccount },
      },
    }) => (
      <Cell align="center">
        <BooleanTag value={canViewAccount} />
      </Cell>
    ),
  },
  {
    width: 190,
    id: "canInitiatePayments",
    title: t("accountMembership.canInitiatePayments"),
    renderTitle: ({ title }) => <HeaderCell align="center" text={title} />,
    renderCell: ({
      item: {
        node: { canInitiatePayments },
      },
    }) => (
      <Cell align="center">
        <BooleanTag value={canInitiatePayments} />
      </Cell>
    ),
  },
  {
    width: 230,
    id: "canManageBeneficiaries",
    title: t("accountMembership.canManageBeneficiaries"),
    renderTitle: ({ title }) => <HeaderCell align="center" text={title} />,
    renderCell: ({
      item: {
        node: { canManageBeneficiaries },
      },
    }) => (
      <Cell align="center">
        <BooleanTag value={canManageBeneficiaries} />
      </Cell>
    ),
  },
  {
    width: 290,
    id: "canManageAccountMembership",
    title: t("accountMembership.canManageAccountMembership"),
    renderTitle: ({ title }) => <HeaderCell align="center" text={title} />,
    renderCell: ({
      item: {
        node: { canManageAccountMembership },
      },
    }) => (
      <Cell align="center">
        <BooleanTag value={canManageAccountMembership} />
      </Cell>
    ),
  },
  {
    width: 180,
    id: "canManageCards",
    title: t("accountMembership.canManageCards"),
    renderTitle: ({ title }) => <HeaderCell align="center" text={title} />,
    renderCell: ({
      item: {
        node: { canManageCards },
      },
    }) => (
      <Cell align="center">
        <BooleanTag value={canManageCards} />
      </Cell>
    ),
  },
  {
    width: 160,
    id: "numberOfCards",
    title: t("accountMembership.numberOfCards"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          cards: { totalCount },
        },
      },
    }) => <TextCell text={String(totalCount)} />,
  },
  {
    width: 200,
    id: "createdAt",
    title: t("accountMembership.createdAt"),
    renderTitle: ({ title, extraInfo }) => {
      return (
        <TrackPressable action="Sort memberships by creation date">
          <HeaderCell
            text={title}
            onPress={direction => {
              extraInfo.onChangeSort?.({ field: "createdAt", direction });
            }}
            sort={
              extraInfo.sortBy?.field === "createdAt"
                ? (extraInfo.sortBy?.direction ?? undefined)
                : undefined
            }
          />
        </TrackPressable>
      );
    },
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("accountMembership.updatedAt"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort memberships by update date">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }) => <TextCell text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
];

export const accountMembershipListDefaultColumns = {
  fixed: defaultFixedColumns,
  active: defaultActiveColumns,
};

type Props = {
  accountMemberships: AccountMembershipListFragment["edges"];
  columns: ColumnChooserConfig<Edge, ExtraInfo>;
  onEndReached: () => void;
  perPage: number;
  isLoading: boolean;
  extraInfo: ExtraInfo;
  getRowLink?: (config: LinkConfig<Edge, ExtraInfo>) => ReactElement | undefined;
  emptyListTitle: string;
  hasSearchOrFilters: boolean;
};

export const AccountMembershipList = ({
  accountMemberships,
  columns,
  onEndReached,
  isLoading,
  perPage,
  extraInfo,
  getRowLink,
  emptyListTitle,
  hasSearchOrFilters,
}: Props) => {
  return (
    <VirtualizedList
      variant="default"
      extraInfo={extraInfo}
      keyExtractor={keyExtractor}
      data={accountMemberships}
      stickedToStartColumns={columns.fixed}
      columns={columns.active}
      onEndReached={onEndReached}
      headerHeight={48}
      rowHeight={48}
      getRowLink={getRowLink}
      marginHorizontal={negativeSpacings[24]}
      loading={{
        isLoading,
        count: perPage,
      }}
      renderEmptyList={() =>
        hasSearchOrFilters ? (
          <EmptyView
            icon="clipboard-search-regular"
            title={t("common.list.noResults")}
            subtitle={t("common.list.noResultsSuggestion")}
          />
        ) : (
          <EmptyView icon="lake-inbox-empty" title={emptyListTitle} />
        )
      }
    />
  );
};
