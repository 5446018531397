import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { useMemo } from "react";
import { match, P } from "ts-pattern";
import { GetPaymentDocument } from "../graphql/partner";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { RouteParams, Router } from "../utils/routes";
import { AccountDetailMerchantProfilePaymentDetail } from "./AccountDetailMerchantProfilePaymentDetail";
import { AccountDetailMerchantProfilePaymentTransactions } from "./AccountDetailMerchantProfilePaymentTransactions";

type Props = {
  params: RouteParams<"AccountDetailMerchantProfilePaymentArea">;
};
export const AccountDetailMerchantProfilePaymentArea = ({ params }: Props) => {
  const [data] = useQuery(GetPaymentDocument, {
    paymentId: params.paymentId,
  });

  const route = Router.useRoute([
    "AccountDetailMerchantProfilePaymentDetail",
    "AccountDetailMerchantProfilePaymentTransactions",
  ]);

  useCrumb(
    useMemo(() => {
      return data
        .toOption()
        .flatMap(result => result.toOption())
        .flatMap(payment => Option.fromNullable(payment.merchantPayment))
        .map(merchantPayment => ({
          label: merchantPayment.id,
          link: Router.AccountDetailMerchantProfilePaymentDetail({
            paymentId: params.paymentId,
            merchantProfileId: params.merchantProfileId,
            projectId: params.projectId,
            projectEnv: params.projectEnv,
            accountId: params.accountId,
          }),
        }))
        .toUndefined();
    }, [params, data]),
  );

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder withTabs={false} />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), () => null)
    .with(AsyncData.P.Done(Result.P.Ok({ merchantPayment: P.nullish })), () => null)
    .with(
      AsyncData.P.Done(Result.P.Ok({ merchantPayment: P.select(P.nonNullable) })),
      merchantPayment => {
        return (
          <>
            <TabView
              tabs={[
                {
                  label: t("merchantPayment.tab.general"),
                  url: Router.AccountDetailMerchantProfilePaymentDetail(params),
                },
                ...(merchantPayment.__typename === "CardMerchantPayment"
                  ? [
                      {
                        label: t("merchantPayment.tab.transactions"),
                        url: Router.AccountDetailMerchantProfilePaymentTransactions(params),
                      },
                    ]
                  : []),
              ]}
              otherLabel={t("common.tabs.other")}
            />

            <Space height={24} />

            {match({ route, merchantPayment })
              .with({ route: { name: "AccountDetailMerchantProfilePaymentDetail" } }, () => (
                <AccountDetailMerchantProfilePaymentDetail merchantPayment={merchantPayment} />
              ))
              .with(
                {
                  route: { name: "AccountDetailMerchantProfilePaymentTransactions" },
                  merchantPayment: { __typename: "CardMerchantPayment" },
                },
                ({ merchantPayment: { id } }) => (
                  <AccountDetailMerchantProfilePaymentTransactions paymentId={id} params={params} />
                ),
              )
              .otherwise(() => (
                <NotFoundPage />
              ))}
          </>
        );
      },
    )

    .exhaustive();
};
