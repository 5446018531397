import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { Cell, CopyableTextCell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { identity } from "@swan-io/lake/src/utils/function";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import dayjs from "dayjs";
import { useMemo } from "react";
import { match, P } from "ts-pattern";
import {
  GetSupportingDocumentCollectionsCountOnlyDocument,
  GetSupportingDocumentCollectionsWithCountDocument,
  GetSupportingDocumentCollectionsWithCountQuery,
  OrderByDirection,
  SupportingDocumentCollectionOrderByFieldInput,
  SupportingDocumentCollectionOrderByInput,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { RouteParams, Router } from "../utils/routes";
import { ColumnChooser, useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { FilterTiles } from "./FilterTiles";
import { TrackPressable } from "./TrackPressable";

const PER_PAGE = 20;

type Props = {
  params: RouteParams<"TransactionsVerificationArea">;
};

type Edge = GetEdge<
  GetSupportingDocumentCollectionsWithCountQuery["supportingDocumentCollections"]
>;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: SupportingDocumentCollectionOrderByInput) => void;
  sortBy?: SupportingDocumentCollectionOrderByInput;
};

const keyExtractor = ({ node: { id } }: Edge) => id;

const getRowLink = ({
  item: {
    node: { relatedInfo },
  },
  extraInfo: { projectEnv, projectId },
}: LinkConfig<Edge, ExtraInfo>) =>
  match(relatedInfo)
    .with(
      { __typename: "SupportingDocumentCollectionTransactionRelatedInfo" },
      ({ transaction: { id } }) => (
        <Link
          to={Router.TransactionsDetailSupportingDocuments({
            projectId,
            projectEnv,
            transactionId: id,
          })}
        />
      ),
    )
    .otherwise(() => undefined);

const sanitizeDocumentPurpose = (document: string) =>
  capitalize(
    document.replace(/([A-Z])([a-z])/g, (_, $1, $2) => ` ${String($1).toLowerCase()}${$2}`).trim(),
  );

const defaultFixedColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 370,
    id: "id",
    title: t("transaction.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
];

const defaultActiveColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 150,
    id: "transactionStatus",
    title: t("transaction.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { relatedInfo },
      },
    }) => (
      <Cell>
        {match(relatedInfo)
          .with(
            { __typename: "SupportingDocumentCollectionTransactionRelatedInfo" },
            ({ transaction: { statusInfo } }) =>
              match(statusInfo)
                .with({ __typename: "BookedTransactionStatusInfo" }, () => (
                  <Tag color="positive">{t("transactions.status.booked")}</Tag>
                ))
                .with({ __typename: "CanceledTransactionStatusInfo" }, () => (
                  <Tag color="gray">{t("transactions.status.canceled")}</Tag>
                ))
                .with({ __typename: "PendingTransactionStatusInfo" }, () => (
                  <Tag color="shakespear">{t("transactions.status.pending")}</Tag>
                ))
                .with({ __typename: "RejectedTransactionStatusInfo" }, () => (
                  <Tag color="negative">{t("transactions.status.rejected")}</Tag>
                ))
                .with({ __typename: "ReleasedTransactionStatusInfo" }, () => (
                  <Tag color="gray">{t("transactions.status.released")}</Tag>
                ))
                .with({ __typename: "UpcomingTransactionStatusInfo" }, () => (
                  <Tag color="shakespear">{t("transactions.status.upcoming")}</Tag>
                ))

                .exhaustive(),
          )
          .otherwise(() => null)}
      </Cell>
    ),
  },

  {
    width: 200,
    id: "createdAt",
    title: t("transaction.createdAt"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort transations by execution date">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "createdAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "createdAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { relatedInfo },
      },
    }) =>
      match(relatedInfo)
        .with(
          { __typename: "SupportingDocumentCollectionTransactionRelatedInfo" },
          ({ transaction: { createdAt } }) => (
            <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
          ),
        )
        .otherwise(() => null),
  },
  {
    width: 200,
    id: "verificationStatus",
    title: t("transactionVerification.verificationStatus"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { statusInfo },
      },
    }) => (
      <Cell>
        {match(statusInfo)
          .with({ __typename: "SupportingDocumentCollectionApprovedStatusInfo" }, () => (
            <Tag color="positive">{t("supportingDocumentCollection.status.Approved")}</Tag>
          ))
          .with({ __typename: "SupportingDocumentCollectionCanceledStatusInfo" }, () => (
            <Tag color="gray">{t("supportingDocumentCollection.status.Canceled")}</Tag>
          ))
          .with({ __typename: "SupportingDocumentCollectionPendingReviewStatusInfo" }, () => (
            <Tag color="shakespear">{t("supportingDocumentCollection.status.PendingReview")}</Tag>
          ))
          .with({ __typename: "SupportingDocumentCollectionRejectedStatusInfo" }, () => (
            <Tag color="negative">{t("supportingDocumentCollection.status.Rejected")}</Tag>
          ))
          .with({ __typename: "SupportingDocumentCollectionWaitingForDocumentStatusInfo" }, () => (
            <Tag color="warning">{t("supportingDocumentCollection.status.WaitingForDocument")}</Tag>
          ))
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 240,
    id: "requiredDocuments",
    title: t("transactionVerification.requiredDoc"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { requiredSupportingDocumentPurposes },
      },
    }) => {
      const purposes = [
        ...new Set(requiredSupportingDocumentPurposes.map(purpose => purpose.name)),
      ];

      return match(purposes)
        .with([], () => null)
        .with([P.select(P.string)], purpose => <TextCell text={sanitizeDocumentPurpose(purpose)} />)
        .with([P.string, ...P.array(P.string)], ([purpose, ...rest]) => (
          <LakeTooltip
            placement="center"
            content={rest.map(purpose => (
              <LakeText key={purpose} color={colors.gray[50]}>
                {sanitizeDocumentPurpose(purpose)}
              </LakeText>
            ))}
          >
            <TextCell text={`${sanitizeDocumentPurpose(purpose)} (+${rest.length})`} />
          </LakeTooltip>
        ))
        .exhaustive();
    },
  },
  {
    width: 240,
    id: "uploadedDocuments",
    title: t("transactionVerification.uploadedDoc"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { supportingDocuments },
      },
    }) => {
      const purposes = [
        ...new Set(
          Array.filterMap(supportingDocuments, supportingDoc =>
            Option.fromNullable(supportingDoc).flatMap(supportingDoc =>
              supportingDoc.statusInfo.status === "NotUploaded"
                ? Option.None()
                : Option.Some(supportingDoc.supportingDocumentPurpose),
            ),
          ),
        ),
      ];

      return match(purposes)
        .with([], () => null)
        .with([P.select(P.string)], purpose => <TextCell text={sanitizeDocumentPurpose(purpose)} />)
        .with([P.string, ...P.array(P.string)], ([purpose, ...rest]) => (
          <LakeTooltip
            placement="center"
            content={rest.map(purpose => (
              <LakeText key={purpose} color={colors.gray[50]}>
                {sanitizeDocumentPurpose(purpose)}
              </LakeText>
            ))}
          >
            <TextCell text={`${sanitizeDocumentPurpose(purpose)} (+${rest.length})`} />
          </LakeTooltip>
        ))
        .exhaustive();
    },
  },
];

export const TransactionVerificationListPage = ({ params }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  useCrumb(
    useMemo(
      () => ({
        label: t("transactionVerification.verifications"),
        link: Router.TransactionsVerificationArea({ projectId, projectEnv }),
      }),
      [projectEnv, projectId],
    ),
  );

  const sortBy: SupportingDocumentCollectionOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<SupportingDocumentCollectionOrderByFieldInput>()
        .with("createdAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const [data, { isLoading, setVariables }] = useQuery(
    GetSupportingDocumentCollectionsWithCountDocument,
    {
      first: PER_PAGE,
      filters: {
        status: Option.fromNullable(params.status)
          .map(status => [status])
          .toUndefined(),
        type: ["Transaction"],
      },
      orderBy: sortBy,
    },
  );

  const [counts] = useQuery(GetSupportingDocumentCollectionsCountOnlyDocument, {});

  const pendingSupportingDocumentCollectionCount = counts
    .toOption()
    .flatMap(result => result.toOption())
    .map(
      ({ pendingSupportingDocumentCollections }) => pendingSupportingDocumentCollections.totalCount,
    );

  const waitingForDocumentSupportingDocumentCollectionCount = counts
    .toOption()
    .flatMap(result => result.toOption())
    .map(
      ({ waitingSupportingDocumentCollections }) => waitingSupportingDocumentCollections.totalCount,
    );

  const extraInfo: ExtraInfo = useMemo(
    () => ({
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("TransactionsVerificationArea", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
    }),
    [projectEnv, projectId, sortBy, params],
  );

  const columns = useColumnChooser("TransactionVerifications", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  return (
    <>
      <FilterTiles
        filters={[
          {
            value: "WaitingForDocument" as const,
            name: t("supportingDocumentCollection.status.WaitingForDocument"),
            color: colors.warning[500],
            count: waitingForDocumentSupportingDocumentCollectionCount,
          },
          {
            value: "PendingReview" as const,
            name: t("supportingDocumentCollection.status.PendingReview"),
            color: colors.shakespear[500],
            count: pendingSupportingDocumentCollectionCount,
          },
        ]}
        selectedFilter={params.status}
        onFilterPressed={filter =>
          Router.push("TransactionsVerificationArea", {
            projectEnv,
            projectId,
            status: match(filter)
              .with("WaitingForDocument", "PendingReview", x => x)
              .otherwise(() => undefined),
          })
        }
      />

      <Space height={16} />

      <Box direction="row">
        <ColumnChooser {...columns} />
      </Box>

      <Space height={12} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={PER_PAGE}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.supportingDocumentCollections}>
            {supportingDocumentCollections => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={supportingDocumentCollections.edges}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                stickedToStartColumns={columns.fixed}
                columns={columns.active}
                headerHeight={48}
                rowHeight={48}
                loading={{
                  isLoading,
                  count: PER_PAGE,
                }}
                getRowLink={getRowLink}
                onEndReached={() => {
                  if (supportingDocumentCollections.pageInfo.hasNextPage === true) {
                    setVariables({
                      after: supportingDocumentCollections.pageInfo.endCursor ?? undefined,
                    });
                  }
                }}
                renderEmptyList={() => (
                  <EmptyView
                    icon="lake-inbox-empty"
                    title={t("accountHolder.list.empty.title")}
                    subtitle={t("accountHolder.list.empty.subtitle")}
                  />
                )}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
