import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import {
  BalanceCell,
  Cell,
  CopyableTextCell,
  HeaderCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Link } from "@swan-io/lake/src/components/Link";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { identity } from "@swan-io/lake/src/utils/function";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import dayjs from "dayjs";
import { isValid } from "iban";
import { useMemo } from "react";
import { match, P } from "ts-pattern";
import {
  GetTransactionsFromPaymentDocument,
  OrderByDirection,
  PaymentTransactionFragment,
  TransactionsOrderByField,
  TransactionsOrderByInput,
} from "../graphql/partner";
import { printMaskedPanFormat } from "../utils/card";
import { formatCurrency, locale, t } from "../utils/i18n";
import { printMaskedIbanFormat } from "../utils/iban";
import { RouteParams, Router } from "../utils/routes";
import { useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

const PER_PAGE = 20;

type Edge = GetEdge<PaymentTransactionFragment>;

type ExtraInfo = {
  onChangeSort?: (sortBy: TransactionsOrderByInput) => void;
  sortBy?: TransactionsOrderByInput;
};

const defaultFixedColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 200,
    id: "id",
    title: t("transaction.id"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort transactions by ID">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "id", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "id"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
];

const defaultActiveColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 120,
    id: "status",
    title: t("transaction.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          statusInfo: { status },
        },
      },
    }) => (
      <Cell>
        {match(status)
          .with("Booked", value => <Tag color="positive">{value}</Tag>)
          .with("Rejected", value => <Tag color="negative">{value}</Tag>)
          .with("Pending", value => <Tag color="shakespear">{value}</Tag>)
          .with("Canceled", value => <Tag color="gray">{value}</Tag>)
          .with("Upcoming", value => <Tag color="shakespear">{value}</Tag>)
          .with("Released", value => <Tag color="gray">{value}</Tag>)
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 200,
    id: "label",
    title: t("transaction.label"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { label },
      },
    }) => <TextCell text={label} />,
  },
  {
    width: 150,
    id: "amount",
    title: t("transaction.amount"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) => {
      const {
        amount: { currency, value },
        side,
      } = node;

      const originalAmount = match(node)
        .with({ __typename: "CardTransaction" }, ({ amount: { value, currency } }) => {
          const unsigned = Number(value);

          return {
            value:
              unsigned *
              (unsigned === 0
                ? 1
                : match(side)
                    .with("Debit", () => -1)
                    .with("Credit", () => 1)
                    .exhaustive()),
            currency,
          };
        })
        .otherwise(() => undefined);

      const unsigned = Number(value);

      return (
        <BalanceCell
          currency={currency}
          value={
            unsigned *
            (unsigned === 0
              ? 1
              : match(side)
                  .with("Debit", () => -1)
                  .with("Credit", () => 1)
                  .exhaustive())
          }
          originalValue={originalAmount}
          formatCurrency={formatCurrency}
        />
      );
    },
  },
  {
    width: 200,
    id: "type",
    title: t("transaction.type"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { type },
      },
    }) => (
      <Cell>
        <Tag color="gray">{type}</Tag>
      </Cell>
    ),
  },
  {
    width: 200,
    id: "paymentMethod",
    title: t("transaction.paymentMethod"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { paymentMethodIdentifier },
      },
    }) => (
      <TextCell
        text={
          isValid(paymentMethodIdentifier)
            ? printMaskedIbanFormat(paymentMethodIdentifier)
            : printMaskedPanFormat(paymentMethodIdentifier)
        }
      />
    ),
  },
  {
    width: 200,
    id: "createdAt",
    title: t("transaction.createdAt"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort transactions by creation date">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "createdAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "createdAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
  {
    width: 180,
    id: "executionDate",
    title: t("transaction.executionDate"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort transactions by execution date">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "executionDate", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "executionDate"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { executionDate },
      },
    }) => (
      <TextCell text={dayjs(executionDate).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
  {
    width: 180,
    id: "reference",
    title: t("transaction.reference"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { reference },
      },
    }) =>
      isNotNullishOrEmpty(reference) ? (
        <CopyableTextCell
          text={reference}
          copyWording={t("copyButton.copyTooltip")}
          copiedWording={t("copyButton.copiedTooltip")}
        />
      ) : null,
  },
  {
    width: 180,
    id: "externalReference",
    title: t("transaction.externalReference"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { externalReference },
      },
    }) => (isNotNullish(externalReference) ? <TextCell text={externalReference} /> : null),
  },
  {
    width: 200,
    id: "bookedBalanceAfter",
    title: t("transaction.bookedBalanceAfter"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { bookedBalanceAfter },
      },
    }) =>
      isNotNullish(bookedBalanceAfter) ? (
        <BalanceCell
          currency={bookedBalanceAfter.currency}
          value={Number(bookedBalanceAfter.value)}
          formatCurrency={formatCurrency}
        />
      ) : null,
  },
];

type Props = {
  paymentId: string;
  params: RouteParams<"AccountDetailMerchantProfilePaymentTransactions">;
};

export const AccountDetailMerchantProfilePaymentTransactions = ({ paymentId, params }: Props) => {
  const [data, { isLoading, setVariables }] = useQuery(GetTransactionsFromPaymentDocument, {
    first: PER_PAGE,
    paymentId: paymentId,
  });

  const columns = useColumnChooser("Account>Merchants>Transactions", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const payment = data.mapOkToResult(data =>
    Option.fromNullable(data.merchantPayment).toResult(undefined),
  );

  const sortBy: TransactionsOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<TransactionsOrderByField>()
        .with("executionDate", "id", "createdAt", "updatedAt", identity)
        .otherwise(() => "executionDate"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv: params.projectEnv,
      projectId: params.projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("AccountDetailMerchantProfilePaymentTransactions", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
    };
  }, [params, sortBy]);

  return match(payment)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
      <VirtualizedListPlaceholder
        headerHeight={48}
        rowHeight={48}
        count={20}
        marginHorizontal={negativeSpacings[24]}
      />
    ))
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(
      AsyncData.P.Done(
        Result.P.Ok({ __typename: "CardMerchantPayment", transactions: P.select() }),
      ),
      data => {
        return (
          <Connection connection={data}>
            {transactions => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={transactions?.edges ?? []}
                keyExtractor={item => item.node.id}
                extraInfo={extraInfo}
                stickedToStartColumns={columns.fixed}
                columns={columns.active}
                headerHeight={48}
                rowHeight={48}
                onEndReached={() => {
                  if (transactions?.pageInfo.hasNextPage === true) {
                    setVariables({ after: transactions.pageInfo.endCursor });
                  }
                }}
                loading={{ isLoading, count: PER_PAGE }}
                renderEmptyList={() => (
                  <EmptyView
                    icon="lake-merchant"
                    borderedIcon={true}
                    title={t("merchantPayment.transactions.noResults")}
                  />
                )}
                getRowLink={({ item }: LinkConfig<Edge, ExtraInfo>) => (
                  <Link
                    to={Router.AccountDetailTransactionsDetail({
                      transactionId: item.node.id,
                      projectId: params.projectId,
                      projectEnv: params.projectEnv,
                      accountId: params.accountId,
                    })}
                  />
                )}
              />
            )}
          </Connection>
        );
      },
    )
    .otherwise(() => (
      <EmptyView
        icon="lake-merchant"
        borderedIcon={true}
        title={t("merchantPayment.transactions.noResults")}
      />
    ));
};
