import { Array, AsyncData, Future, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  LinkConfig,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { identity } from "@swan-io/lake/src/utils/function";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import {
  emptyToUndefined,
  isNotNullish,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  filter,
  FiltersStack,
  FiltersState,
  useFiltersProps,
} from "@swan-io/shared-business/src/components/Filters";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useCallback, useMemo, useState } from "react";
import { match, P } from "ts-pattern";
import { AccountList, accountListDefaultColumns, ExtraInfo } from "../components/AccountList";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "../components/ColumnChooser";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import {
  AccountListFragment,
  AccountOrderByFieldInput,
  AccountOrderByInput,
  AccountStatus,
  ExportAccountDataDocument,
  GetAccountsDocument,
  OrderByDirection,
  PaymentLevel,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";

type Edge = GetEdge<AccountListFragment>;

type Props = {
  params: RouteParams<"AccountsRoot">;
  membershipEmail: string | undefined;
};

const paymentLevelsList = ["Unlimited", "Limited"] as const;
const statusList = ["Opened", "Suspended", "Closing", "Closed"] as const;

const PER_PAGE = 20;

export const AccountListPage = ({ params, membershipEmail }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [exportDataVisible, { open: openExportDataModal, close: closeExportDataModal }] =
    useDisclosure(false);

  const [exportAccountData, accountDataExported] = useMutation(ExportAccountDataDocument);

  const onSubmit = () => {
    if (isNotNullish(membershipEmail)) {
      exportAccountData({
        input: {
          email: membershipEmail,
          filters,
        },
      })
        .mapOkToResult(data => Option.fromNullable(data.exportAccountData).toResult("No data"))
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(() => {
          closeExportDataModal();
          showToast({ variant: "success", title: t("acountStatements.dataExported") });
        })
        .tapError(error => {
          showToast({ variant: "error", title: translateError(error), error });
        });
    }
  };

  const filters = useMemo((): AccountFilters => {
    return {
      paymentLevels: isNotNullish(params.paymentLevels)
        ? Array.filterMap(params.paymentLevels, item =>
            match(item)
              .with(...paymentLevelsList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      status: isNotNullish(params.status)
        ? Array.filterMap(params.status, item =>
            match(item)
              .with(...statusList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
    } as const;
  }, [params]);

  const sortBy: AccountOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<AccountOrderByFieldInput>()
        .with("createdAt", "updatedAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const search = nullishOrEmptyToUndefined(params.search?.trim());
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountsDocument, {
    first: PER_PAGE,
    filters: { ...filters, search },
    orderBy: sortBy,
  });

  const columns = useColumnChooser("Accounts", {
    defaultFixedColumns: accountListDefaultColumns.fixed,
    defaultActiveColumns: accountListDefaultColumns.active,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("AccountsRoot", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
      reexecuteQuery: reload,
    };
  }, [projectEnv, projectId, reload, sortBy, params]);

  const getRowLink = useCallback(
    ({
      item: {
        node: { id },
      },
      extraInfo: { projectEnv, projectId },
    }: LinkConfig<Edge, ExtraInfo>) => (
      <Link to={Router.AccountDetailRoot({ projectId, projectEnv, accountId: id })} />
    ),
    [],
  );

  const accounts = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ accounts }) => accounts);

  const totalCount = accounts.map(({ totalCount }) => totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <FiltersForm
        membershipEmail={membershipEmail}
        openExportDataModal={openExportDataModal}
        filters={filters}
        columns={columns}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("AccountsRoot", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("AccountsRoot", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={PER_PAGE}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.accounts}>
            {accounts => (
              <>
                <AccountList
                  accounts={accounts.edges}
                  columns={columns}
                  hasSearchOrFilters={hasSearchOrFilters}
                  onEndReached={() => {
                    if (accounts.pageInfo.hasNextPage === true) {
                      setVariables({ after: accounts.pageInfo.endCursor ?? undefined });
                    }
                  }}
                  isLoading={isLoading}
                  perPage={PER_PAGE}
                  extraInfo={extraInfo}
                  emptyListTitle={t("accounts.list.empty.title")}
                  getRowLink={getRowLink}
                />

                <LakeModal
                  icon="arrow-download-filled"
                  visible={exportDataVisible}
                  onPressClose={closeExportDataModal}
                  title={t("dataExport.exportData")}
                >
                  <LakeText color={colors.gray[900]}>
                    {accounts.totalCount > 100_000
                      ? t("dataExport.exportData.largeExportDescription")
                      : t("dataExport.exportData.smallExportDescription")}
                  </LakeText>

                  <LakeButtonGroup paddingBottom={0}>
                    <TrackPressable action="Export account data">
                      <LakeButton
                        color="current"
                        grow={true}
                        onPress={onSubmit}
                        loading={accountDataExported.isLoading()}
                      >
                        {t("dataExport.exportData.confirm")}
                      </LakeButton>
                    </TrackPressable>
                  </LakeButtonGroup>
                </LakeModal>
              </>
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};

const filtersDefinition = {
  paymentLevels: filter.checkbox<PaymentLevel>({
    label: t("account.paymentLevel"),
    items: [
      { value: "Unlimited", label: t("accounts.filters.paymentLevels.unlimited") },
      { value: "Limited", label: t("accounts.filters.paymentLevels.limited") },
    ],
  }),
  status: filter.checkbox<AccountStatus>({
    label: t("account.status"),
    items: [
      { value: "Opened", label: t("account.status.opened") },
      { value: "Suspended", label: t("account.status.suspended") },
      { value: "Closed", label: t("account.status.closed") },
      { value: "Closing", label: t("account.status.closing") },
    ],
  }),
};

type AccountFilters = FiltersState<typeof filtersDefinition>;

type FiltersFormProps = {
  filters: AccountFilters;
  columns: ColumnChooserConfig<Edge, ExtraInfo>;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: AccountFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
  openExportDataModal: () => void;
  membershipEmail: string | undefined;
};

const FiltersForm = ({
  filters,
  columns,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
  openExportDataModal,
  membershipEmail,
}: FiltersFormProps) => {
  const filtersProps = useFiltersProps({ filtersDefinition, filters });
  const isExportVisible = useTgglFlag("dataExportAccount").getOr(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser {...filtersProps.chooser} />
        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        {isExportVisible && isNotNullish(membershipEmail) && (
          <>
            <LakeTooltip placement="center" content={t("dataExport.exportData")}>
              <TrackPressable action="Export user data">
                <LakeButton
                  mode="secondary"
                  size="small"
                  onPress={openExportDataModal}
                  icon="arrow-download-filled"
                  ariaLabel={t("dataExport.exportData")}
                />
              </TrackPressable>
            </LakeTooltip>

            <Space width={8} />
          </>
        )}

        <TrackPressable action="Refresh accounts list">
          <LakeButton
            ariaLabel={t("common.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />
      <FiltersStack {...filtersProps.stack} onChangeFilters={onChangeFilters} />
    </>
  );
};
