import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useFocusReset } from "@swan-io/chicane";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useMemo, useRef } from "react";
import { View } from "react-native";
import { P, match } from "ts-pattern";
import { GetAccountHolderDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, accountHolderDetailRoutes } from "../utils/routes";
import { AccountHolderDetailAccounts } from "./AccountHolderDetailAccounts";
import { AccountHolderDetailGeneral } from "./AccountHolderDetailGeneral";
import { ErrorView } from "./ErrorView";
import { Redirect } from "./Redirect";
import { UltimateBeneficialOwnerList } from "./UltimateBeneficialOwnerList";

type Props = {
  accountHolderId: string;
};

export const AccountHolderDetail = ({ accountHolderId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(accountHolderDetailRoutes);
  const containerRef = useRef(null);

  useFocusReset({ route, containerRef });

  const [data] = useQuery(GetAccountHolderDocument, {
    id: accountHolderId,
    orderBy: { direction: "Desc" },
    maxIdentifications: 8,
  });

  useCrumb(
    useMemo(
      () =>
        data
          .toOption()
          .flatMap(result => result.toOption())
          .flatMap(data => Option.fromNullable(data.accountHolder))
          .map(accountHolder => ({
            label: accountHolder.info.name,
            link: Router.HoldersDetailRoot({ projectId, projectEnv, accountHolderId }),
          }))
          .toUndefined(),
      [projectId, accountHolderId, projectEnv, data],
    ),
  );

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok({ accountHolder: P.nullish })), () => (
      <Redirect to={Router.HoldersRoot({ projectId, projectEnv })} />
    ))
    .with(
      AsyncData.P.Done(Result.P.Ok({ accountHolder: P.select(P.nonNullable) })),
      accountHolder => (
        <>
          <TabView
            tabs={[
              {
                label: t("accountHolder.general"),
                url: Router.HoldersDetailRoot({ projectId, projectEnv, accountHolderId }),
              },
              {
                label: t("accountHolder.accounts"),
                url: Router.HoldersDetailAccounts({ projectId, projectEnv, accountHolderId }),
              },
              // {
              //   label: t("accountHolder.paymentMandates"),
              //   url: Router.HoldersDetailsPaymentMandates({ projectId, projectEnv, accountHolderId }),
              // },
              ...(accountHolder.info.type === "Company"
                ? [
                    {
                      label: t("accountHolder.ultimateBeneficialOwners"),
                      url: Router.HoldersDetailUltimateBeneficialOwners({
                        projectId,
                        projectEnv,
                        accountHolderId,
                      }),
                    },
                  ]
                : []),
            ]}
            otherLabel={t("common.tabs.other")}
          />

          <Space height={24} />

          <View ref={containerRef} style={commonStyles.fill}>
            {match(route)
              .with({ name: "HoldersDetailRoot" }, () => (
                <LakeScrollView>
                  <AccountHolderDetailGeneral accountHolder={accountHolder} />
                </LakeScrollView>
              ))
              .with({ name: "HoldersDetailAccounts" }, ({ params }) => (
                <AccountHolderDetailAccounts
                  accountHolderId={accountHolderId}
                  accountHolderName={accountHolder.info.name}
                  params={params}
                />
              ))
              .with({ name: "HoldersDetailUltimateBeneficialOwners" }, () =>
                match(accountHolder.info)
                  .with({ __typename: "AccountHolderCompanyInfo" }, info => (
                    <UltimateBeneficialOwnerList
                      columnChooserId="AccountHolder>UltimateBeneficialOwners"
                      ultimateBeneficialOwners={info.individualUltimateBeneficialOwners}
                      extraInfo={{ projectEnv, projectId, reexecuteQuery: () => {} }}
                      emptyListTitle={t("ultimateBeneficialOwner.list.emptyTitle")}
                    />
                  ))
                  .otherwise(() => <NotFoundPage />),
              )
              .otherwise(() => (
                <NotFoundPage />
              ))}
          </View>
        </>
      ),
    )
    .exhaustive();
};
