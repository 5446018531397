import { Option } from "@swan-io/boxed";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { Stack } from "@swan-io/lake/src/components/Stack";
import { Tick } from "@swan-io/lake/src/components/Tick";
import { backgroundColor, colors, radii, spacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { Pressable, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: colors.gray[100],
    borderRadius: radii[8],
    flexBasis: "0%",
    flexGrow: 1,
    flexShrink: 1,
    paddingVertical: spacings[12],
    paddingHorizontal: spacings[20],
  },
  selected: {
    borderColor: colors.current[500],
    backgroundColor: backgroundColor.accented,
  },
});

type Filter = {
  name: string;
  value: string;
  help?: string;
  count: Option<number>;
  color: string;
};

type FilterTilesProps = {
  filters: Filter[];
  selectedFilter?: string;
  onFilterPressed: (value: string) => void;
};

export const FilterTiles = ({ filters, selectedFilter, onFilterPressed }: FilterTilesProps) => (
  <Stack direction="row" space={12}>
    {filters.map((filter, i) => (
      <Pressable
        key={`filter-tile-${filter.value}-${i}`}
        onPress={() => onFilterPressed(filter.value)}
        style={[styles.item, filter.value === selectedFilter && styles.selected]}
      >
        <Tick size="small" color={filter.color} />
        <Space width={12} />

        {filter.count.isSome() && (
          <LakeText variant="semibold" color={filter.color}>
            {filter.count.get()}
          </LakeText>
        )}

        <Space width={8} />

        <LakeText variant="smallMedium" numberOfLines={1} color={colors.gray[900]}>
          {filter.name}
        </LakeText>

        {isNotNullish(filter.help) && (
          <>
            <Fill minWidth={8} />
            <InformationTooltip icon="question-circle-regular" iconSize={16} text={filter.help} />
          </>
        )}
      </Pressable>
    ))}
  </Stack>
);
