import { useMutation } from "@swan-io/graphql-client";
import { Avatar } from "@swan-io/lake/src/components/Avatar";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Popover } from "@swan-io/lake/src/components/Popover";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { Flag } from "@swan-io/shared-business/src/components/Flag";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { getCountryByCCA3, isCountryCCA3 } from "@swan-io/shared-business/src/constants/countries";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import dayjs from "dayjs";
import { Fragment, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { DeactivateUserDocument, UserDetailFragment } from "../graphql/partner";
import { t } from "../utils/i18n";
import { TrackPressable } from "./TrackPressable";
import { UserIdentificationProcessHelp } from "./UserIdentificationProcessHelp";

const styles = StyleSheet.create({
  tileContainer: {
    flex: 1,
  },
  userInfoLabel: {
    minHeight: 65,
  },
  unknownValue: {
    fontStyle: "italic",
  },
  align: {
    display: "flex",
    alignItems: "center",
  },
  identificationLevels: {
    flexWrap: "wrap",
  },
  identificationLevel: {
    marginBottom: spacings[8],
  },
  grid: {
    flexShrink: 1,
    flexGrow: 1,
  },
  emailsPopover: {
    padding: spacings[24],
  },
});

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

type Props = {
  user: UserDetailFragment;
};

export const UserDetailGeneral = ({ user }: Props) => {
  const birthDate = isNotNullishOrEmpty(user.birthDate) ? dayjs(user.birthDate).format("LL") : null;
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const [deactivateUser, userDeactivation] = useMutation(DeactivateUserDocument);
  const [isDeactivateUserModalVisible, setIsDeactivateUserModalVisible] = useState(false);

  const handleDeactivateUser = () => {
    deactivateUser({
      input: {
        userId: user.id,
      },
    })
      .mapOk(data => data.deactivateUser)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        showToast({ variant: "success", title: t("toast.success.deactivateUserSuccessful") });
        setIsDeactivateUserModalVisible(false);
      })
      .tapError(error => {
        match(error)
          .with({ __typename: "UserCannotBeDeactivatedRejection" }, () => {
            showToast({ variant: "error", error, title: t("toast.error.userCannotBeDeactivated") });
          })
          .otherwise(() => {
            showToast({ variant: "error", error, title: translateError(error) });
          });
      });
  };

  const {
    id,
    fullName,
    mobilePhoneNumber,
    nationalityCCA3,
    preferredNotificationChannel,
    joinedAt,
    identificationLevels,
    status,
    accountMembershipsForEmails,
  } = user;

  const isExpert = identificationLevels?.expert ?? false;
  const isQES = identificationLevels?.QES ?? false;
  const isPVID = identificationLevels?.PVID ?? false;

  const emails = [...new Set(accountMembershipsForEmails.edges.map(item => item.node.email))];

  const [firstEmail] = emails;

  return (
    <>
      <Tile>
        <Box direction="row" alignItems="center" justifyContent="spaceBetween">
          <Box direction="row" alignItems="center">
            <Avatar user={user} size={68} />
            <Space width={32} />

            <View>
              <LakeHeading level={2} variant="h3">
                {fullName}
              </LakeHeading>

              <Space height={8} />

              <Box direction="row" alignItems="center">
                {isNotNullishOrEmpty(birthDate) ? (
                  <LakeText key="user-details-birthdate" variant="smallRegular">
                    {birthDate}
                  </LakeText>
                ) : null}
              </Box>
            </View>
          </Box>
        </Box>

        <Space height={32} />

        <Box direction="column" alignItems="stretch">
          <View style={styles.tileContainer}>
            <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
              <ReadOnlyFieldList>
                <LakeLabel
                  style={styles.userInfoLabel}
                  label={t("user.details.user.phoneNumber")}
                  type="view"
                  render={() =>
                    mobilePhoneNumber != null ? (
                      <LakeText color={colors.gray[900]}>{mobilePhoneNumber}</LakeText>
                    ) : (
                      UNKNOWN_VALUE
                    )
                  }
                />

                {isNotNullish(status) && (
                  <LakeLabel
                    style={styles.userInfoLabel}
                    label={t("user.details.user.status")}
                    type="view"
                    render={() => (
                      <>
                        {match(status)
                          .with("Active", () => (
                            <Tag color="positive">{t("user.details.user.status.active")}</Tag>
                          ))
                          .with("Blocked", () => (
                            <Tag color="negative">{t("user.details.user.status.blocked")}</Tag>
                          ))
                          .with("Deactivated", () => (
                            <Tag color="gray">{t("user.details.user.status.deactivated")}</Tag>
                          ))
                          .exhaustive()}
                      </>
                    )}
                  />
                )}

                <LakeLabel
                  style={styles.userInfoLabel}
                  label={t("user.details.user.joinedProject")}
                  type="view"
                  render={() => (
                    <LakeText color={colors.gray[900]}>{dayjs(joinedAt).format("LL")}</LakeText>
                  )}
                />

                {match(user)
                  .with({ status: "Deactivated" }, ({ updatedAt }) => (
                    <LakeLabel
                      style={styles.userInfoLabel}
                      label={t("user.details.user.deactivatedDate")}
                      type="view"
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(updatedAt).format("LL")}
                        </LakeText>
                      )}
                    />
                  ))
                  .otherwise(() => null)}

                <LakeLabel
                  style={styles.userInfoLabel}
                  label={t("user.details.user.userId")}
                  type="view"
                  render={() => (
                    <LakeText color={colors.gray[900]} numberOfLines={1}>
                      {id}
                    </LakeText>
                  )}
                  actions={
                    <LakeCopyButton
                      valueToCopy={id}
                      copyText={t("copyButton.copyTooltip")}
                      copiedText={t("copyButton.copiedTooltip")}
                    />
                  }
                />
              </ReadOnlyFieldList>

              <ReadOnlyFieldList>
                <LakeLabel
                  style={styles.userInfoLabel}
                  label={t("user.details.user.nationality")}
                  type="view"
                  render={() => {
                    const country = isCountryCCA3(nationalityCCA3)
                      ? getCountryByCCA3(nationalityCCA3)
                      : undefined;

                    return (
                      <LakeText style={styles.align} numberOfLines={1} color={colors.gray[900]}>
                        {isNotNullish(country) ? (
                          <>
                            <Flag code={country.cca2} width={14} />
                            <Space width={8} />

                            {country.name}
                          </>
                        ) : (
                          UNKNOWN_VALUE
                        )}
                      </LakeText>
                    );
                  }}
                />

                <LakeLabel
                  style={styles.userInfoLabel}
                  label={t("user.details.user.notificationMethod")}
                  type="view"
                  render={() => (
                    <Tag>
                      {preferredNotificationChannel === "App" ? t("common.app") : t("common.sms")}
                    </Tag>
                  )}
                />

                <LakeLabel
                  style={styles.userInfoLabel}
                  label={t("user.details.user.identificationLevel")}
                  type="view"
                  actions={
                    <LakeButton
                      mode="tertiary"
                      onPress={() => setIsHelpModalOpen(true)}
                      icon="question-circle-regular"
                      ariaLabel={t("user.details.identifications.help")}
                    />
                  }
                  render={() => (
                    <Box direction="row" alignItems="center" style={styles.identificationLevels}>
                      <Tag
                        label={t("user.details.user.identificationLevel.expert")}
                        color={isExpert ? "positive" : "gray"}
                        style={styles.identificationLevel}
                      >
                        {isExpert ? t("common.true") : t("common.false")}
                      </Tag>

                      <Space width={12} />

                      <Tag
                        label={t("user.details.user.identificationLevel.qes")}
                        color={isQES ? "positive" : "gray"}
                        style={styles.identificationLevel}
                      >
                        {isQES ? t("common.true") : t("common.false")}
                      </Tag>

                      <Space width={12} />

                      <Tag
                        label={t("user.details.user.identificationLevel.pvid")}
                        color={isPVID ? "positive" : "gray"}
                        style={styles.identificationLevel}
                      >
                        {isPVID ? t("common.true") : t("common.false")}
                      </Tag>
                    </Box>
                  )}
                />

                <LakeLabel
                  style={styles.userInfoLabel}
                  label={t("user.details.user.email")}
                  type="view"
                  render={() =>
                    firstEmail != null ? (
                      <LakeText color={colors.gray[900]} numberOfLines={1}>
                        {firstEmail}
                      </LakeText>
                    ) : (
                      UNKNOWN_VALUE
                    )
                  }
                  actions={
                    firstEmail != null ? (
                      <>
                        <LakeCopyButton
                          valueToCopy={firstEmail}
                          copyText={t("copyButton.copyTooltip")}
                          copiedText={t("copyButton.copiedTooltip")}
                        />

                        {emails.length > 1 && (
                          <>
                            <Space width={12} />
                            <UserEmails emails={emails} />
                          </>
                        )}
                      </>
                    ) : null
                  }
                />
              </ReadOnlyFieldList>
            </Grid>
          </View>
        </Box>

        <LakeModal
          visible={isHelpModalOpen}
          onPressClose={() => setIsHelpModalOpen(false)}
          title={t("user.details.identifications.identificationProcess")}
          icon="question-circle-regular"
        >
          <UserIdentificationProcessHelp />
        </LakeModal>
      </Tile>

      {user.status === "Active" && (
        <LakeButtonGroup>
          <TrackPressable action="Deactivate user">
            <LakeButton
              color="negative"
              icon="subtract-circle-regular"
              mode="secondary"
              onPress={() => setIsDeactivateUserModalVisible(true)}
            >
              {t("user.details.deactivateUser")}
            </LakeButton>
          </TrackPressable>
        </LakeButtonGroup>
      )}

      <LakeModal
        icon="subtract-circle-regular"
        visible={isDeactivateUserModalVisible}
        title={t("user.details.deactivateUser")}
        onPressClose={() => setIsDeactivateUserModalVisible(false)}
        color="negative"
      >
        <LakeText>{t("user.details.deactivateUser.description")}</LakeText>
        <Space height={32} />

        <LakeButtonGroup>
          <LakeButton
            mode="secondary"
            color="gray"
            onPress={() => setIsDeactivateUserModalVisible(false)}
            grow={true}
          >
            {t("common.cancel")}
          </LakeButton>

          <LakeButton
            color="negative"
            onPress={() => handleDeactivateUser()}
            loading={userDeactivation.isLoading()}
            grow={true}
          >
            {t("user.details.deactivate")}
          </LakeButton>
        </LakeButtonGroup>
      </LakeModal>
    </>
  );
};

type UserEmailsProps = {
  emails: string[];
};

const UserEmails = ({ emails }: UserEmailsProps) => {
  const ref = useRef<View>(null);
  const [opened, setOpened] = useState(false);

  return (
    <>
      <LakeButton
        ref={ref}
        mode="tertiary"
        onPress={() => setOpened(true)}
        icon="add-circle-regular"
        ariaLabel={t("common.moreInfo")}
      />

      <Popover referenceRef={ref} visible={opened} onDismiss={() => setOpened(false)}>
        <View style={styles.emailsPopover}>
          {emails.map((email, index) => (
            <Fragment key={index}>
              <Box direction="row" alignItems="center">
                <LakeText color={colors.gray[900]}>{email}</LakeText>
                <Fill minWidth={24} />

                <LakeCopyButton
                  valueToCopy={email}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              </Box>

              {index < emails.length - 1 && <Space height={16} />}
            </Fragment>
          ))}
        </View>
      </Popover>
    </>
  );
};
