import { useFocusReset } from "@swan-io/chicane";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, BreadcrumbsRoot } from "@swan-io/lake/src/components/Breadcrumbs";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { LakeDocumentationLink } from "./DocumentationLink";
import { MerchantList } from "./MerchantList";

const styles = StyleSheet.create({
  container: {
    ...commonStyles.fill,
  },
});

export const MerchantArea = () => {
  const { projectEnv, projectId } = useProjectInfo();

  const route = Router.useRoute(["MerchantArea", "MerchantList"]);
  const containerRef = useRef(null);

  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("merchantProfile.all"),
        link: Router.MerchantList({ projectId, projectEnv }),
      },
    ],
    [projectEnv, projectId],
  );

  useFocusReset({ route, containerRef });

  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <Box direction="row" justifyContent="spaceBetween" alignItems="center">
        <Breadcrumbs />

        <Box direction="row" justifyContent="end" alignItems="center">
          <LakeDocumentationLink to="merchant">{t("common.learnMore")}</LakeDocumentationLink>
          <Space width={16} />
          <InformationTooltip text={t("merchantProfile.subtitle")} />
        </Box>
      </Box>

      <Space height={16} />

      <View ref={containerRef} style={styles.container}>
        {match(route)
          .with({ name: "MerchantList" }, ({ params }) => <MerchantList params={params} />)
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </View>
    </BreadcrumbsRoot>
  );
};
