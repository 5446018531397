import { AsyncData } from "@swan-io/boxed";
import { getLocation } from "@swan-io/chicane";
import { ClientContext, useQuery } from "@swan-io/graphql-client";
import { ErrorBoundary } from "@swan-io/lake/src/components/ErrorBoundary";
import { backgroundColor } from "@swan-io/lake/src/constants/design";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { ToastStack } from "@swan-io/shared-business/src/components/ToastStack";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { AuthenticatedArea } from "./AuthenticatedArea";
import { ErrorView } from "./components/ErrorView";
import { Redirect } from "./components/Redirect";
import { AuthStatusDocument } from "./graphql/exposed-internal";
import { LoginPage } from "./pages/LoginPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { liveExposedInternalClient } from "./utils/gql";
import { logFrontendError } from "./utils/logger";
import { Router } from "./utils/routes";

const styles = StyleSheet.create({
  root: {
    backgroundColor: backgroundColor.default,
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: "0%",
    minWidth: 1000,
  },
});

const AppContainer = () => {
  const route = Router.useRoute([
    "BaseLogin",

    "BaseSsoProjectPicker",
    "BaseRoot",
    "BaseStart",
    "BaseProjects",
    "ProjectArea",
  ]);

  // This request adds some waterfall, but it gives us the ability
  // to distinguish between the case where the user isn't logged in
  // and any server error. In the former we want to redirect to the
  // login page, in the latter just to show an error page
  const [data] = useQuery(AuthStatusDocument, {});

  const loginInfo = data
    .mapOk(data => data.user?.id != null)
    .map(result => ({ isLoggedIn: result.getOr(false) }));

  return match(loginInfo)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => null)
    .with(AsyncData.P.Done(P.select()), ({ isLoggedIn }) => {
      return match(route)
        .with({ name: "BaseLogin" }, ({ params: { redirectTo, sessionExpired } }) =>
          isLoggedIn ? (
            // Skip login and redirect to the wished URL
            <Redirect to={redirectTo ?? Router.BaseRoot()} />
          ) : (
            <LoginPage
              redirectTo={redirectTo}
              sessionExpired={isNotNullishOrEmpty(sessionExpired)}
            />
          ),
        )
        .with(
          {
            name: P.union(
              "BaseSsoProjectPicker",
              "BaseRoot",
              "BaseProjects",
              "BaseStart",
              "ProjectArea",
            ),
          },
          () =>
            isLoggedIn ? (
              <AuthenticatedArea />
            ) : (
              // Go to login
              <Redirect to={Router.BaseLogin({ redirectTo: getLocation().toString() })} />
            ),
        )
        .with(P.nullish, () => <NotFoundPage />)
        .exhaustive();
    })
    .exhaustive();
};

export const App = () => {
  return (
    // Catch all boundary
    <ErrorBoundary onError={error => logFrontendError(error)} fallback={() => <ErrorView />}>
      <ClientContext.Provider value={liveExposedInternalClient}>
        <View style={styles.root}>
          <AppContainer />
          <ToastStack />
        </View>
      </ClientContext.Provider>
    </ErrorBoundary>
  );
};
