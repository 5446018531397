import { AsyncData, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { Switch } from "@swan-io/lake/src/components/Switch";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { TilePlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { colors, radii, spacings } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { Flag } from "@swan-io/shared-business/src/components/Flag";
import { CountryCCA3, getCCA2forCCA3 } from "@swan-io/shared-business/src/constants/countries";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { ReactNode, useMemo } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { CopyTextButton } from "../components/CopyTextButton";
import { ErrorView } from "../components/ErrorView";
import { LinkInputButton } from "../components/LinkInputButton";
import {
  GetOnboardingPageDocument,
  UpdateOnboardingProjectSettingsDocument,
} from "../graphql/exposed-internal";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { env } from "../utils/env";
import { t } from "../utils/i18n";
import { useTgglFlag } from "../utils/tggl";

type CountryTileInfo = {
  cca3: CountryCCA3;
  title: string;
  description: string;
};

const styles = StyleSheet.create({
  flag: {
    fontSize: 32,
  },
  comingSoonTile: {
    borderColor: colors.gray[100],
    borderRadius: radii[8],
    borderWidth: 1,
    height: "100%",
    maxHeight: 368,
    padding: spacings[32],
  },
});

const ComingSoonTile = ({ flag, title }: { flag: ReactNode; title: string }) => (
  <Box alignItems="center" justifyContent="center" style={styles.comingSoonTile}>
    {isNotNullish(flag) && (
      <LakeText color={colors.gray[900]} style={styles.flag}>
        {flag}
      </LakeText>
    )}

    <LakeHeading numberOfLines={1} variant="h3" level={3}>
      {title}
    </LakeHeading>

    <Space height={24} />
    <LakeText>{t("projectSettings.onboarding.urls.comingSoon")}</LakeText>
  </Box>
);

type CountryTileProps = {
  country: CountryTileInfo;
  hasPublicUrls: boolean;
  onboardingBaseUrl: string;
};

const CountryTile = ({ country, hasPublicUrls, onboardingBaseUrl }: CountryTileProps) => {
  return (
    <Tile
      title={country.title}
      description={country.description}
      icon={<Flag width={28} code={getCCA2forCCA3(country.cca3)} />}
    >
      <LakeLabel
        label={t("projectSettings.onboarding.urls.individuals")}
        render={id => {
          const value = hasPublicUrls
            ? `${onboardingBaseUrl}/onboarding/individual/start?accountCountry=${country.cca3}`
            : "";

          return (
            <Box direction="row">
              <LakeTextInput
                id={id}
                placeholder={t("projectSettings.onboarding.urlNotActivated")}
                readOnly={true}
                value={value}
                hideErrors={true}
              />

              <Space width={12} />
              <CopyTextButton value={value} disabled={!hasPublicUrls} />
              <Space width={12} />
              <LinkInputButton size="small" external={true} to={value} disabled={!hasPublicUrls} />
            </Box>
          );
        }}
      />

      <Space height={20} />

      <LakeLabel
        label={t("projectSettings.onboarding.urls.companies")}
        render={id => {
          const value = hasPublicUrls
            ? `${onboardingBaseUrl}/onboarding/company/start?accountCountry=${country.cca3}`
            : "";

          return (
            <Box direction="row">
              <LakeTextInput
                id={id}
                placeholder={t("projectSettings.onboarding.urlNotActivated")}
                readOnly={true}
                value={value}
                hideErrors={true}
              />

              <Space width={12} />
              <CopyTextButton value={value} disabled={!hasPublicUrls} />
              <Space width={12} />
              <LinkInputButton size="small" external={true} to={value} disabled={!hasPublicUrls} />
            </Box>
          );
        }}
      />
    </Tile>
  );
};

export const OnboardingSettingsPage = () => {
  const { projectId, projectEnv } = useProjectInfo();
  const canEditSettingsOnboarding = usePermissions(projectEnv).settingsOnboarding.write;
  const [data, { reload }] = useQuery(GetOnboardingPageDocument, { projectId });

  const italianIbanBackFlag = useTgglFlag("swan_supports_local_italian_iban").getOr(false);
  const italianIbanFrontFlag = useTgglFlag("swan_supports_local_italian_iban_dashboard").getOr(
    false,
  );

  const supportsItalianIBANs = italianIbanBackFlag && italianIbanFrontFlag;

  const [updateBankingFeatures] = useMutation(UpdateOnboardingProjectSettingsDocument);
  const isLive = projectEnv === "live";

  const onboardingHost = isLive ? env.ONBOARDING_LIVE_URL : env.ONBOARDING_SANDBOX_URL;
  const onboardingBaseUrl = `${onboardingHost}/projects/${projectId}`;

  const updateHasPublicUrls = (value: boolean) => {
    updateBankingFeatures({
      input: { arePublicOnboardingEnabled: value, projectId },
    })
      .mapOk(data => data.updateOnboardingProjectSettings)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        reload();
        showToast({
          variant: "success",
          title: t("toast.success.changedSetting", {
            setting: t("projectSettings.onboarding.urls.publicUrls"),
          }),
        });
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  const onboardingCountries: CountryTileInfo[] = useMemo(
    () => [
      {
        cca3: "FRA" as const,
        title: t("projectSettings.onboarding.urls.frenchIban"),
        description: t("projectSettings.onboarding.urls.frenchIban.description"),
      },
      {
        cca3: "DEU" as const,
        title: t("projectSettings.onboarding.urls.germanIban"),
        description: t("projectSettings.onboarding.urls.germanIban.description"),
      },
      {
        cca3: "ESP" as const,
        title: t("projectSettings.onboarding.urls.spanishIban"),
        description: t("projectSettings.onboarding.urls.spanishIban.description"),
      },
      {
        cca3: "NLD" as const,
        title: t("projectSettings.onboarding.urls.dutchIban"),
        description: t("projectSettings.onboarding.urls.dutchIban.description"),
      },
      ...(supportsItalianIBANs
        ? [
            {
              cca3: "ITA" as const,
              title: t("projectSettings.onboarding.urls.italianIban"),
              description: t("projectSettings.onboarding.urls.italianIban.description"),
            },
          ]
        : []),
    ],
    [supportsItalianIBANs],
  );

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TilePlaceholder />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
      const hasPublicUrls = data.onboardingProjectSettings.arePublicOnboardingEnabled;

      return (
        <>
          <Tile
            title={t("projectSettings.onboarding.urls.title")}
            description={
              <>
                {t("projectSettings.onboarding.subtitle")}

                <br />

                {t("projectSettings.onboarding.urls.text")}
              </>
            }
            footer={
              <LakeAlert
                variant="warning"
                anchored={true}
                title={t("projectSettings.onboarding.urls.activatedWarning")}
              />
            }
          >
            <Box direction="row" alignItems="center">
              <LakeTooltip
                placement="left"
                content={t("common.action.denied")}
                disabled={canEditSettingsOnboarding}
              >
                <Switch
                  value={hasPublicUrls}
                  onValueChange={updateHasPublicUrls}
                  disabled={!canEditSettingsOnboarding}
                />
              </LakeTooltip>

              <Space width={12} />

              <LakeText color={colors.gray[900]}>
                {t("projectSettings.onboarding.urls.publicUrls")}
              </LakeText>
            </Box>
          </Tile>

          <Space height={20} />

          <TileGrid>
            {onboardingCountries.map(country => (
              <CountryTile
                key={country.cca3}
                country={country}
                hasPublicUrls={hasPublicUrls}
                onboardingBaseUrl={onboardingBaseUrl}
              />
            ))}

            {supportsItalianIBANs ? null : (
              <ComingSoonTile
                key="ITA"
                flag={<Flag width={28} code="IT" />}
                title={t("projectSettings.onboarding.urls.italianIban")}
              />
            )}
          </TileGrid>
        </>
      );
    })
    .exhaustive();
};
