import { AsyncData, Future, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Avatar } from "@swan-io/lake/src/components/Avatar";
import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, CopyableTextCell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Toggle } from "@swan-io/lake/src/components/Toggle";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import {
  emptyToUndefined,
  isNotNullish,
  isNotNullishOrEmpty,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import {
  FiltersStack,
  FiltersState,
  filter,
  useFiltersProps,
} from "@swan-io/shared-business/src/components/Filters";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import {
  CountryCCA3,
  countries,
  isCountryCCA3,
} from "@swan-io/shared-business/src/constants/countries";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useMemo, useState } from "react";
import { P, isMatching, match } from "ts-pattern";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "../components/ColumnChooser";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import {
  ExportUserDataDocument,
  GetUsersDocument,
  IdentificationLevelInput,
  PreferredNotificationChannel,
  UserListInfoFragment,
  UserStatus,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
};

type Props = {
  params: RouteParams<"UserList">;
  membershipEmail: string | undefined;
};

const PER_PAGE = 20;

const keyExtractor = ({ id }: UserListInfoFragment) => id;

const getRowLink = ({
  item: { id },
  extraInfo: { projectEnv, projectId },
}: LinkConfig<UserListInfoFragment, ExtraInfo>) => (
  <Link to={Router.UserDetailRoot({ projectId, projectEnv, userId: id })} />
);

const defaultFixedColumns: ColumnConfig<UserListInfoFragment, ExtraInfo>[] = [
  {
    width: 300,
    id: "name",
    title: t("user.list.name"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => {
      return (
        <Cell>
          <Avatar user={item} size={32} />
          <Space width={16} />

          <LakeText variant="medium" color={colors.gray[900]}>
            {item.fullName}
          </LakeText>
        </Cell>
      );
    },
  },
];

const defaultActiveColumns: ColumnConfig<UserListInfoFragment, ExtraInfo>[] = [
  {
    width: 300,
    id: "email",
    title: t("user.list.email"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => {
      const email = item.accountMemberships.edges[0]?.node.email;

      if (isNotNullishOrEmpty(email)) {
        return (
          <CopyableTextCell
            text={email}
            copyWording={t("copyButton.copyTooltip")}
            copiedWording={t("copyButton.copiedTooltip")}
          />
        );
      }

      return <TextCell text="-" />;
    },
  },
  {
    width: 200,
    id: "phone",
    title: t("user.list.phone"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { mobilePhoneNumber } }) => <TextCell text={mobilePhoneNumber ?? "-"} />,
  },
  {
    width: 400,
    id: "id",
    title: t("user.list.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { id } }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
];

export const UserListPage = ({ params, membershipEmail }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [exportDataVisible, { open: openExportDataModal, close: closeExportDataModal }] =
    useDisclosure(false);

  const [exportUserData, userDataExported] = useMutation(ExportUserDataDocument);

  const onSubmit = () => {
    if (isNotNullish(membershipEmail)) {
      exportUserData({
        input: {
          email: membershipEmail,
        },
      })
        .mapOkToResult(data => Option.fromNullable(data.exportUserData).toResult("No data"))
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(() => {
          closeExportDataModal();
          showToast({ variant: "success", title: t("acountStatements.dataExported") });
        })
        .tapError(error => {
          showToast({ variant: "error", title: translateError(error), error });
        });
    }
  };

  const { status = "Active" } = params;

  const filters = useMemo((): UserFilters => {
    return {
      identificationLevel: isMatching(P.union("Expert" as const, "PVID" as const, "QES" as const))(
        params.identificationLevel,
      )
        ? params.identificationLevel
        : undefined,
      nationalityCCA3: isCountryCCA3(params.nationalityCCA3) ? params.nationalityCCA3 : undefined,
      preferredNotificationChannel: preferredNotificationChannel.is(
        params.preferredNotificationChannel,
      )
        ? params.preferredNotificationChannel
        : undefined,
    } as const;
  }, [params]);

  const search = nullishOrEmptyToUndefined(params.search?.trim());

  const hasSearchOrFilters =
    isNotNullish(search) || status === "Inactive" || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetUsersDocument, {
    first: PER_PAGE,
    filters: {
      ...filters,
      status: match(status)
        .returnType<UserStatus[]>()
        .with("Active", () => ["Active"])
        .with("Inactive", () => ["Blocked", "Deactivated"])
        .exhaustive(),
      search,
    },
  });

  const columns = useColumnChooser("Users", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
    };
  }, [projectEnv, projectId]);

  const totalCount = data
    .toOption()
    .flatMap(data => data.toOption())
    .map(data => data.users.totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <FiltersForm
        openExportDataModal={openExportDataModal}
        membershipEmail={membershipEmail}
        filters={filters}
        columns={columns}
        search={search}
        totalCount={totalCount}
        params={params}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("UserList", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("UserList", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.users}>
            {users => (
              <>
                <VirtualizedList
                  variant="default"
                  marginHorizontal={negativeSpacings[24]}
                  data={users.edges.map(item => item.node)}
                  keyExtractor={keyExtractor}
                  extraInfo={extraInfo}
                  stickedToStartColumns={columns.fixed}
                  columns={columns.active}
                  headerHeight={48}
                  rowHeight={48}
                  getRowLink={getRowLink}
                  onEndReached={() => {
                    if (users.pageInfo.hasNextPage === true) {
                      setVariables({ after: users.pageInfo.endCursor ?? undefined });
                    }
                  }}
                  loading={{
                    isLoading,
                    count: PER_PAGE,
                  }}
                  renderEmptyList={() =>
                    hasSearchOrFilters ? (
                      <EmptyView
                        icon="clipboard-search-regular"
                        title={t("common.list.noResults")}
                        subtitle={t("common.list.noResultsSuggestion")}
                      />
                    ) : (
                      <EmptyView
                        icon="lake-inbox-empty"
                        title={t("user.list.empty.title")}
                        subtitle={t("user.list.empty.subtitle")}
                      />
                    )
                  }
                />

                <LakeModal
                  icon="arrow-download-filled"
                  visible={exportDataVisible}
                  onPressClose={closeExportDataModal}
                  title={t("dataExport.exportData")}
                >
                  <LakeText color={colors.gray[900]}>
                    {users.totalCount > 100_000
                      ? t("dataExport.exportData.largeExportDescription")
                      : t("dataExport.exportData.smallExportDescription")}
                  </LakeText>

                  <LakeButtonGroup paddingBottom={0}>
                    <TrackPressable action="Export user data">
                      <LakeButton
                        color="current"
                        grow={true}
                        onPress={onSubmit}
                        loading={userDataExported.isLoading()}
                      >
                        {t("dataExport.exportData.confirm")}
                      </LakeButton>
                    </TrackPressable>
                  </LakeButtonGroup>
                </LakeModal>
              </>
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};

const identificationLevels: IdentificationLevelInput[] = ["Expert", "PVID", "QES"];

const preferredNotificationChannel = deriveUnion<PreferredNotificationChannel>({
  App: true,
  Sms: true,
});

const filtersDefinition = {
  identificationLevel: filter.radio<IdentificationLevelInput>({
    label: t("user.list.filter.identificationLevel"),
    items: identificationLevels.map(value => ({ value, label: value })),
  }),
  nationalityCCA3: filter.radio<CountryCCA3>({
    label: t("user.list.filter.nationality"),
    items: countries.map(country => ({ label: country.name, value: country.cca3 })),
  }),
  preferredNotificationChannel: filter.radio<PreferredNotificationChannel>({
    label: t("user.list.filter.notificationChannel"),
    items: preferredNotificationChannel.array.map(value => ({ value, label: value })),
  }),
};

type UserFilters = FiltersState<typeof filtersDefinition>;

type FiltersFormProps = {
  filters: UserFilters;
  columns: ColumnChooserConfig<UserListInfoFragment, ExtraInfo>;
  search: string | undefined;
  totalCount: Option<number>;
  params: RouteParams<"UserList">;
  onChangeFilters: (filters: UserFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
  openExportDataModal: () => void;
  membershipEmail: string | undefined;
};

const FiltersForm = ({
  filters,
  columns,
  search,
  totalCount,
  params,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
  openExportDataModal,
  membershipEmail,
}: FiltersFormProps) => {
  const filtersProps = useFiltersProps({ filtersDefinition, filters });
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row">
        <Box direction="row">
          <FilterChooser {...filtersProps.chooser} />
          <Space width={8} />
          <ColumnChooser {...columns} />
          <Space width={8} />

          {isNotNullish(membershipEmail) && (
            <>
              <LakeTooltip placement="center" content={t("dataExport.exportData")}>
                <TrackPressable action="Export user data">
                  <LakeButton
                    mode="secondary"
                    size="small"
                    onPress={openExportDataModal}
                    icon="arrow-download-filled"
                    ariaLabel={t("dataExport.exportData")}
                  />
                </TrackPressable>
              </LakeTooltip>

              <Space width={8} />
            </>
          )}

          <TrackPressable action="Refresh users list">
            <LakeButton
              ariaLabel={t("common.refresh")}
              mode="secondary"
              size="small"
              icon="arrow-counterclockwise-filled"
              loading={isRefreshing}
              onPress={() => {
                setIsRefreshing(true);
                onRefresh().tap(() => setIsRefreshing(false));
              }}
            />
          </TrackPressable>
        </Box>

        <Fill minWidth={16} />

        <Box direction="row" alignItems="center">
          <Toggle
            value={params.status === "Active" || params.status === undefined}
            onToggle={status =>
              Router.push("UserList", { ...params, status: status ? "Active" : "Inactive" })
            }
            onLabel={t("user.list.filter.active")}
            offLabel={t("user.list.filter.inactive")}
          />

          <Space width={16} />

          <LakeSearchField
            placeholder={t("common.search")}
            initialValue={search ?? ""}
            onChangeText={text => onChangeSearch(emptyToUndefined(text))}
            renderEnd={() =>
              totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
            }
          />
        </Box>
      </Box>

      <Space height={12} />
      <FiltersStack {...filtersProps.stack} onChangeFilters={onChangeFilters} />
    </>
  );
};
