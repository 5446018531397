import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { capitalize } from "@swan-io/lake/src/utils/string";
import dayjs from "dayjs";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { GetPaymentQuery } from "../graphql/partner";
import { formatCurrency, locale, t } from "../utils/i18n";

const styles = StyleSheet.create({
  unknownValue: {
    fontStyle: "italic",
  },
});

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

type Props = {
  merchantPayment: NonNullable<GetPaymentQuery["merchantPayment"]>;
};
export const AccountDetailMerchantProfilePaymentDetail = ({ merchantPayment }: Props) => {
  const { authorizedAt, canceledAt, capturedAt, createdAt, disputedAt, refundedAt, rejectedAt } =
    merchantPayment;

  return (
    <LakeScrollView>
      <TileGrid>
        <Tile title={t("merchantPayment.paymentDetails")}>
          <ReadOnlyFieldList>
            <LakeLabel
              type="view"
              label={t("merchantPayment.amount")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {formatCurrency(
                    Number(merchantPayment.amount.value),
                    merchantPayment.amount.currency,
                  )}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.status")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {match(merchantPayment.statusInfo)
                    .with({ __typename: "MerchantPaymentAuthorized" }, () => (
                      <Tag color="shakespear">{t("merchantPayment.status.authorized")}</Tag>
                    ))
                    .with({ __typename: "MerchantPaymentCaptured" }, () => (
                      <Tag color="positive">{t("merchantPayment.status.captured")}</Tag>
                    ))
                    .with({ __typename: "MerchantPaymentInitiated" }, () => (
                      <Tag color="swan">{t("merchantPayment.status.initiated")}</Tag>
                    ))
                    .with({ __typename: "MerchantPaymentRejected" }, () => (
                      <Tag color="negative">{t("merchantPayment.status.rejected")}</Tag>
                    ))
                    .with({ __typename: "MerchantPaymentCanceled" }, () => (
                      <Tag color="warning">{t("merchantPayment.status.canceled")}</Tag>
                    ))
                    .otherwise(() => null)}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.id")}
              render={() => <LakeText color={colors.gray[900]}>{merchantPayment.id}</LakeText>}
              actions={
                <LakeCopyButton
                  valueToCopy={merchantPayment.id}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              }
            />

            {match(merchantPayment.statusInfo)
              .with({ __typename: "MerchantPaymentRejected" }, ({ rejectedReasonCode }) => (
                <LakeLabel
                  type="view"
                  label={t("merchantPayment.detail.rejectionReason")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {capitalize(
                        rejectedReasonCode
                          .replace(
                            /([A-Z])([a-z])/g,
                            (_, $1, $2) => ` ${String($1).toLowerCase()}${$2}`,
                          )
                          .trim(),
                      )}
                    </LakeText>
                  )}
                />
              ))
              .otherwise(() => null)}

            <LakeLabel
              type="view"
              label={t("merchantPayment.label")}
              render={() =>
                isNotNullish(merchantPayment.label) ? (
                  <LakeText color={colors.gray[900]}>{merchantPayment.label}</LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.detail.reference")}
              render={() =>
                isNotNullish(merchantPayment.reference) ? (
                  <LakeText color={colors.gray[900]}>{merchantPayment.reference}</LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.detail.externalReference")}
              render={() =>
                isNotNullish(merchantPayment.reference) ? (
                  <LakeText color={colors.gray[900]}>{merchantPayment.externalReference}</LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.detail.paymentLinkId")}
              render={() =>
                isNotNullish(merchantPayment.reference) ? (
                  <LakeText color={colors.gray[900]}>{merchantPayment.paymentLink?.id}</LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
              actions={
                isNotNullish(merchantPayment.paymentLink) ? (
                  <LakeCopyButton
                    valueToCopy={merchantPayment.paymentLink.id}
                    copyText={t("copyButton.copyTooltip")}
                    copiedText={t("copyButton.copiedTooltip")}
                  />
                ) : null
              }
            />

            {match(merchantPayment.statusInfo)
              .with({ __typename: "MerchantPaymentRejected" }, () => (
                <ReadOnlyFieldList>
                  <LakeLabel
                    type="view"
                    label={t("merchantPayment.createdAt")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                      </LakeText>
                    )}
                  />

                  {isNotNullish(rejectedAt) && (
                    <LakeLabel
                      type="view"
                      label={t("merchantPayment.rejectedAt")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(rejectedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      )}
                    />
                  )}
                </ReadOnlyFieldList>
              ))
              .with({ __typename: "MerchantPaymentCanceled" }, () => (
                <ReadOnlyFieldList>
                  <LakeLabel
                    type="view"
                    label={t("merchantPayment.createdAt")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                      </LakeText>
                    )}
                  />

                  {isNotNullish(canceledAt) && (
                    <LakeLabel
                      type="view"
                      label={t("merchantPayment.canceledAt")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(canceledAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      )}
                    />
                  )}
                </ReadOnlyFieldList>
              ))
              .otherwise(() => (
                <ReadOnlyFieldList>
                  <LakeLabel
                    type="view"
                    label={t("merchantPayment.createdAt")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                      </LakeText>
                    )}
                  />

                  {isNotNullish(authorizedAt) && (
                    <LakeLabel
                      type="view"
                      label={t("merchantPayment.authorizedAt")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(authorizedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      )}
                    />
                  )}

                  {isNotNullish(authorizedAt) && (
                    <LakeLabel
                      type="view"
                      label={t("merchantPayment.authorizedAt")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(authorizedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      )}
                    />
                  )}

                  {isNotNullish(capturedAt) && (
                    <LakeLabel
                      type="view"
                      label={t("merchantPayment.capturedAt")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(capturedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      )}
                    />
                  )}

                  {isNotNullish(disputedAt) && (
                    <LakeLabel
                      type="view"
                      label={t("merchantPayment.disputedAt")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(disputedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      )}
                    />
                  )}

                  {isNotNullish(refundedAt) && (
                    <LakeLabel
                      type="view"
                      label={t("merchantPayment.refundedAt")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(refundedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      )}
                    />
                  )}
                </ReadOnlyFieldList>
              ))}
          </ReadOnlyFieldList>
        </Tile>

        <Tile title={t("merchantPayment.balances")}>
          <ReadOnlyFieldList>
            <LakeLabel
              type="view"
              label={t("merchantPayment.balances.authorized")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {formatCurrency(
                    Number(merchantPayment.balance.totalAuthorized.value),
                    merchantPayment.balance.totalAuthorized.currency,
                  )}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.balances.cancel")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {formatCurrency(
                    Number(merchantPayment.balance.availableToCancel.value),
                    merchantPayment.balance.availableToCancel.currency,
                  )}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.balances.canceled")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {formatCurrency(
                    Number(merchantPayment.balance.totalCanceled.value),
                    merchantPayment.balance.totalCanceled.currency,
                  )}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.balances.capture")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {formatCurrency(
                    Number(merchantPayment.balance.availableToCapture.value),
                    merchantPayment.balance.availableToCapture.currency,
                  )}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.balances.captured")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {formatCurrency(
                    Number(merchantPayment.balance.totalCaptured.value),
                    merchantPayment.balance.totalCaptured.currency,
                  )}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.balances.refund")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {formatCurrency(
                    Number(merchantPayment.balance.availableToRefund.value),
                    merchantPayment.balance.availableToRefund.currency,
                  )}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.balances.refunded")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {formatCurrency(
                    Number(merchantPayment.balance.totalRefunded.value),
                    merchantPayment.balance.totalRefunded.currency,
                  )}
                </LakeText>
              )}
            />
          </ReadOnlyFieldList>
        </Tile>

        {match(merchantPayment)
          .with({ __typename: "CardMerchantPayment" }, ({ threeDs }) => (
            <Tile title={t("merchantPayment.authenticationDetails")}>
              <ReadOnlyFieldList>
                <LakeLabel
                  type="view"
                  label={t("merchantPayment.threeDsRequested")}
                  render={() =>
                    match(threeDs)
                      .with({ requested: true }, () => (
                        <Tag color="positive">{t("common.true")}</Tag>
                      ))
                      .with({ requested: false }, () => (
                        <Tag color="negative">{t("common.false")}</Tag>
                      ))
                      .otherwise(() => <LakeText color={colors.gray[900]}>{"-"}</LakeText>)
                  }
                />

                {match(threeDs?.statusInfo)
                  .with({ __typename: "SuccessfulThreeDs" }, () => (
                    <LakeLabel
                      type="view"
                      label={t("merchantPayment.threeDsStatus")}
                      render={() => (
                        <Tag color="positive">{t("merchantPayment.threeDsStatus.successful")}</Tag>
                      )}
                    />
                  ))
                  .with({ __typename: "FailedThreeDs" }, ({ reasonCode }) => (
                    <ReadOnlyFieldList>
                      <LakeLabel
                        type="view"
                        label={t("merchantPayment.threeDsStatus")}
                        render={() => (
                          <LakeText color={colors.gray[900]}>
                            {
                              <Tag color="negative">
                                {t("merchantPayment.threeDsStatus.failed")}
                              </Tag>
                            }
                          </LakeText>
                        )}
                      />

                      <LakeLabel
                        type="view"
                        label={t("merchantPayment.threeDsStatus.reason")}
                        render={() => <LakeText color={colors.gray[900]}>{reasonCode}</LakeText>}
                      />
                    </ReadOnlyFieldList>
                  ))
                  .otherwise(() => null)}
              </ReadOnlyFieldList>
            </Tile>
          ))
          .otherwise(() => null)}

        <Tile title={t("merchantPayment.payerDetail")}>
          <ReadOnlyFieldList>
            <LakeLabel
              type="view"
              label={t("merchantPayment.paymentMethodType")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {match(merchantPayment.paymentMethod.type)
                    .with("Card", () => (
                      <Tag color="darkPink">{t("merchantPaymentMethods.card")}</Tag>
                    ))
                    .with("Check", () => (
                      <Tag color="shakespear">{t("merchantPaymentMethods.check")}</Tag>
                    ))
                    .with("InternalDirectDebitB2b", () => (
                      <Tag color="mediumSladeBlue">
                        {t("merchantPaymentMethods.internalDirectDebitB2b")}
                      </Tag>
                    ))
                    .with("InternalDirectDebitStandard", () => (
                      <Tag color="mediumSladeBlue">
                        {t("merchantPaymentMethods.internalDirectDebitStandard")}
                      </Tag>
                    ))
                    .with("SepaDirectDebitB2b", () => (
                      <Tag color="live">{t("merchantPaymentMethods.sepaDirectDebitB2b")}</Tag>
                    ))
                    .with("SepaDirectDebitCore", () => (
                      <Tag color="live">{t("merchantPaymentMethods.sepaDirectDebitCore")}</Tag>
                    ))
                    .otherwise(() => null)}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.paymentMethodId")}
              render={() => (
                <LakeText color={colors.gray[900]}>{merchantPayment.paymentMethod.id}</LakeText>
              )}
              actions={
                <LakeCopyButton
                  valueToCopy={merchantPayment.paymentMethod.id}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              }
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.address")}
              render={() =>
                isNotNullish(merchantPayment.billingAddress?.addressLine1) ? (
                  <LakeText color={colors.gray[900]}>
                    {merchantPayment.billingAddress.addressLine1}
                  </LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.postalCode")}
              render={() =>
                isNotNullish(merchantPayment.billingAddress?.postalCode) ? (
                  <LakeText color={colors.gray[900]}>
                    {merchantPayment.billingAddress?.postalCode}
                  </LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.city")}
              render={() =>
                isNotNullish(merchantPayment.billingAddress?.city) ? (
                  <LakeText color={colors.gray[900]}>
                    {merchantPayment.billingAddress?.city}
                  </LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
            />

            <LakeLabel
              type="view"
              label={t("merchantPayment.country")}
              render={() =>
                isNotNullish(merchantPayment.billingAddress?.city) ? (
                  <LakeText color={colors.gray[900]}>
                    {merchantPayment.billingAddress?.country}
                  </LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
            />
          </ReadOnlyFieldList>
        </Tile>
      </TileGrid>
    </LakeScrollView>
  );
};
