import { Array, AsyncData, Future, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  Cell,
  CopyableTextCell,
  HeaderCell,
  LinkCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { InformationTooltip, LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tick } from "@swan-io/lake/src/components/Tick";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import {
  backgroundColor,
  colors,
  invariantColors,
  negativeSpacings,
  radii,
  shadows,
  spacings,
} from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { deriveUnion, identity } from "@swan-io/lake/src/utils/function";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import {
  emptyToUndefined,
  isNotNullish,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  filter,
  FiltersStack,
  FiltersState,
  useFiltersProps,
} from "@swan-io/shared-business/src/components/Filters";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { match, P } from "ts-pattern";
import { VerificationStatusTag } from "../components/BusinessTags";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "../components/ColumnChooser";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import {
  AccountHolderOrderByFieldInput,
  AccountHolderOrderByInput,
  AccountHolderStatus,
  AccountHolderType,
  ExportAccountHolderDataDocument,
  GetAccountHoldersQuery,
  GetAccountHoldersWithCountDocument,
  OrderByDirection,
  VerificationStatus,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, locale, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";

const styles = StyleSheet.create({
  verificationFilterLink: {
    backgroundColor: backgroundColor.accented,
    display: "flex",
    boxShadow: shadows.tile,

    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: colors.gray[100],
    borderRadius: radii[8],
    flexBasis: "0%",
    flexGrow: 1,
    flexShrink: 1,
    paddingVertical: spacings[12],
    paddingHorizontal: spacings[20],
  },
  verificationFilterLinkHovered: {
    boxShadow: shadows.tileHover,
  },
});

type Props = {
  params: RouteParams<"HoldersRoot">;
  membershipEmail: string | undefined;
};

type Edge = GetEdge<GetAccountHoldersQuery["accountHolders"]>;

const keyExtractor = ({ node: { id } }: Edge) => id;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: AccountHolderOrderByInput) => void;
  sortBy?: AccountHolderOrderByInput;
};

const getRowLink = ({
  item: {
    node: { id },
  },
  extraInfo: { projectEnv, projectId },
}: LinkConfig<Edge, ExtraInfo>) => (
  <Link to={Router.HoldersDetailRoot({ projectId, projectEnv, accountHolderId: id })} />
);

const defaultFixedColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 150,
    id: "type",
    title: t("accountHolder.type"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          info: { type },
        },
      },
    }) => {
      return (
        <Cell>
          {match(type)
            .with("Individual", () => (
              <Tag color="darkPink" icon="person-regular">
                {t("accountHolder.individual")}
              </Tag>
            ))
            .with("Company", () => (
              <Tag color="shakespear" icon="building-regular">
                {t("accountHolder.company")}
              </Tag>
            ))
            .exhaustive()}
        </Cell>
      );
    },
  },
];

const defaultActiveColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 280,
    id: "name",
    title: t("accountHolder.name"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          info: { name },
        },
      },
    }) => <TextCell variant="medium" text={name} />,
  },
  {
    width: 300,
    id: "id",
    title: t("accountHolder.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 300,
    id: "onboardingId",
    title: t("accountHolder.onboarding"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { onboarding },
      },
      extraInfo: { projectEnv, projectId },
    }) =>
      onboarding != null ? (
        <LinkCell
          onPress={() =>
            Router.push("OnboardingDetailRoot", {
              projectId,
              projectEnv,
              onboardingId: onboarding.id,
            })
          }
        >
          {onboarding.id}
        </LinkCell>
      ) : null,
  },
  {
    width: 120,
    id: "status",
    title: t("accountHolder.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { statusInfo },
      },
    }) => (
      <Cell>
        {match(statusInfo?.status)
          .with("Enabled", value => <Tag color="positive">{value}</Tag>)
          .with("Suspended", value => <Tag color="warning">{value}</Tag>)
          .with("Canceled", value => <Tag color="negative">{value}</Tag>)
          .with(undefined, () => null)
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 240,
    id: "verificationStatus",
    title: t("accountHolder.verificationStatus"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { verificationStatusInfo },
      },
    }) => (
      <Cell>
        <VerificationStatusTag verificationStatusInfo={verificationStatusInfo} />
      </Cell>
    ),
  },
  {
    width: 250,
    id: "createdAt",
    title: t("accountHolder.createdAt"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort account holders by creation date">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "createdAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "createdAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { createdDate },
      },
    }) => (
      <TextCell text={dayjs(createdDate).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
  {
    width: 250,
    id: "updatedAt",
    title: t("accountHolder.updatedAt"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort account holders by update date">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedDate },
      },
    }) => (
      <TextCell text={dayjs(updatedDate).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
];

const typeList = ["Company", "Individual"] as const;
const statusList = ["Canceled", "Enabled", "Suspended"] as const;

const verificationStatus = deriveUnion<VerificationStatus>({
  NotStarted: true,
  Pending: true,
  WaitingForInformation: true,
  Verified: true,
  Refused: true,
});

const PER_PAGE = 20;

export const AccountHolderListPage = ({ params, membershipEmail }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [exportDataVisible, { open: openExportDataModal, close: closeExportDataModal }] =
    useDisclosure(false);

  const [exportAccountHolderData, accountHolderDataExported] = useMutation(
    ExportAccountHolderDataDocument,
  );

  const onSubmit = () => {
    if (isNotNullish(membershipEmail)) {
      exportAccountHolderData({
        input: {
          email: membershipEmail,
          filters,
        },
      })
        .mapOkToResult(data =>
          Option.fromNullable(data.exportAccountHolderData).toResult("No data"),
        )
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(() => {
          closeExportDataModal();
          showToast({ variant: "success", title: t("acountStatements.dataExported") });
        })
        .tapError(error => {
          showToast({ variant: "error", title: translateError(error), error });
        });
    }
  };

  const filters = useMemo((): AccountHolderFilters => {
    return {
      types: isNotNullish(params.types)
        ? Array.filterMap(params.types, item =>
            match(item)
              .with(...typeList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      status: isNotNullish(params.status)
        ? Array.filterMap(params.status, item =>
            match(item)
              .with(...statusList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      verificationStatus: isNotNullish(params.verificationStatus)
        ? Array.filterMap(params.verificationStatus, item =>
            match(item)
              .with(verificationStatus.P, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
    } as const;
  }, [params]);

  const sortBy: AccountHolderOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<AccountHolderOrderByFieldInput>()
        .with("createdAt", "updatedAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const search = nullishOrEmptyToUndefined(params.search?.trim());
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountHoldersWithCountDocument, {
    first: PER_PAGE,
    filters: { ...filters, search },
    orderBy: sortBy,
  });

  const columns = useColumnChooser("AccountHolders", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("HoldersRoot", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
    };
  }, [projectEnv, projectId, sortBy, params]);

  const holders = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ accountHolders }) => accountHolders);
  const notStartedAccountHoldersCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ notStartedAccountHoldersCount }) => notStartedAccountHoldersCount.totalCount);
  const pendingAccountHoldersCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ pendingAccountHoldersCount }) => pendingAccountHoldersCount.totalCount);
  const waitingForInformationAccountHoldersCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(
      ({ waitingForInformationAccountHoldersCount }) =>
        waitingForInformationAccountHoldersCount.totalCount,
    );

  const totalCount = holders.map(({ totalCount }) => totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <Box direction="row">
        {!data.isLoading() && (
          <>
            <Link
              disabled={notStartedAccountHoldersCount.getOr(0) === 0}
              to={Router.HoldersVerificationArea({
                projectEnv,
                projectId,
                ...filters,
                verificationStatus: "NotStarted",
              })}
              style={({ hovered }) => [
                styles.verificationFilterLink,
                hovered && styles.verificationFilterLinkHovered,
              ]}
            >
              <Tick size="small" color={colors.gray[900]} />
              <Space width={12} />

              {notStartedAccountHoldersCount.isSome() && (
                <LakeText variant="semibold" color={colors.gray[900]}>
                  {notStartedAccountHoldersCount.get()}
                </LakeText>
              )}

              <Space width={8} />

              <LakeText variant="smallMedium" numberOfLines={1} color={invariantColors.black}>
                {t("accountHolder.verificationStatus.notStarted")}
              </LakeText>

              <Fill minWidth={8} />

              <InformationTooltip
                iconSize={16}
                icon="question-circle-regular"
                text={t("accountHolder.verificationStatus.notStarted.info")}
              />

              <Space width={12} />
              <Icon size={20} name="arrow-right-filled" color={colors.gray[600]} />
            </Link>

            <Space width={12} />

            <Link
              disabled={waitingForInformationAccountHoldersCount.getOr(0) === 0}
              to={Router.HoldersVerificationArea({
                projectEnv,
                projectId,
                ...filters,
                verificationStatus: "WaitingForInformation",
              })}
              style={({ hovered }) => [
                styles.verificationFilterLink,
                hovered && styles.verificationFilterLinkHovered,
              ]}
            >
              <Tick size="small" color={colors.warning[500]} />
              <Space width={12} />

              {waitingForInformationAccountHoldersCount.isSome() && (
                <LakeText variant="semibold" numberOfLines={1} color={colors.warning[500]}>
                  {waitingForInformationAccountHoldersCount.get()}
                </LakeText>
              )}

              <Space width={8} />

              <LakeText variant="smallMedium" color={invariantColors.black}>
                {t("accountHolder.verificationStatus.waitingForInformation")}
              </LakeText>

              <Fill minWidth={8} />

              <InformationTooltip
                iconSize={16}
                icon="question-circle-regular"
                text={t("accountHolder.verificationStatus.waitingForInformation.info")}
              />

              <Space width={12} />
              <Icon size={20} name="arrow-right-filled" color={colors.gray[600]} />
            </Link>

            <Space width={12} />

            <Link
              disabled={pendingAccountHoldersCount.getOr(0) === 0}
              to={Router.HoldersVerificationArea({
                projectEnv,
                projectId,
                ...filters,
                verificationStatus: "Pending",
              })}
              style={({ hovered }) => [
                styles.verificationFilterLink,
                hovered && styles.verificationFilterLinkHovered,
              ]}
            >
              <Tick size="small" color={colors.shakespear[500]} />
              <Space width={12} />

              {pendingAccountHoldersCount.isSome() && (
                <LakeText variant="semibold" color={colors.shakespear[500]}>
                  {pendingAccountHoldersCount.get()}
                </LakeText>
              )}

              <Space width={8} />

              <LakeText variant="smallMedium" numberOfLines={1} color={invariantColors.black}>
                {t("accountHolder.verificationStatus.pending")}
              </LakeText>

              <Fill minWidth={8} />

              <InformationTooltip
                iconSize={16}
                icon="question-circle-regular"
                text={t("accountHolder.verificationStatus.pending.info")}
              />

              <Space width={12} />
              <Icon size={20} name="arrow-right-filled" color={colors.gray[600]} />
            </Link>
          </>
        )}
      </Box>

      <Space height={16} />

      <FiltersForm
        membershipEmail={membershipEmail}
        openExportDataModal={openExportDataModal}
        filters={filters}
        columns={columns}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("HoldersRoot", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("HoldersRoot", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={PER_PAGE}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.accountHolders}>
            {accountHolders => (
              <>
                <VirtualizedList
                  variant="default"
                  marginHorizontal={negativeSpacings[24]}
                  data={accountHolders.edges ?? []}
                  keyExtractor={keyExtractor}
                  extraInfo={extraInfo}
                  stickedToStartColumns={columns.fixed}
                  columns={columns.active}
                  headerHeight={48}
                  rowHeight={48}
                  loading={{
                    isLoading,
                    count: PER_PAGE,
                  }}
                  getRowLink={getRowLink}
                  onEndReached={() => {
                    if (accountHolders.pageInfo.hasNextPage === true) {
                      setVariables({ after: accountHolders.pageInfo.endCursor ?? undefined });
                    }
                  }}
                  renderEmptyList={() =>
                    hasSearchOrFilters ? (
                      <EmptyView
                        icon="clipboard-search-regular"
                        title={t("common.list.noResults")}
                        subtitle={t("common.list.noResultsSuggestion")}
                      />
                    ) : (
                      <EmptyView
                        icon="lake-inbox-empty"
                        title={t("accountHolder.list.empty.title")}
                        subtitle={t("accountHolder.list.empty.subtitle")}
                      />
                    )
                  }
                />

                <LakeModal
                  icon="arrow-download-filled"
                  visible={exportDataVisible}
                  onPressClose={closeExportDataModal}
                  title={t("dataExport.exportData")}
                >
                  <LakeText color={colors.gray[900]}>
                    {accountHolders.totalCount > 100_000
                      ? t("dataExport.exportData.largeExportDescription")
                      : t("dataExport.exportData.smallExportDescription")}
                  </LakeText>

                  <LakeButtonGroup paddingBottom={0}>
                    <TrackPressable action="Export account holders data">
                      <LakeButton
                        color="current"
                        grow={true}
                        onPress={onSubmit}
                        loading={accountHolderDataExported.isLoading()}
                      >
                        {t("dataExport.exportData.confirm")}
                      </LakeButton>
                    </TrackPressable>
                  </LakeButtonGroup>
                </LakeModal>
              </>
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};

const filtersDefinition = {
  types: filter.checkbox<AccountHolderType>({
    label: t("accountHolder.type"),
    items: [
      { value: "Individual", label: t("accountHolder.individual") },
      { value: "Company", label: t("accountHolder.company") },
    ],
  }),
  status: filter.checkbox<AccountHolderStatus>({
    label: t("accountHolder.status"),
    items: [
      { value: "Canceled", label: t("accountHolder.status.canceled") },
      { value: "Enabled", label: t("accountHolder.status.enabled") },
      { value: "Suspended", label: t("accountHolder.status.suspended") },
    ],
  }),
  verificationStatus: filter.checkbox<VerificationStatus>({
    label: t("accountHolder.verificationStatus"),
    items: [
      { value: "NotStarted", label: t("accountHolder.verificationStatus.notStarted") },
      {
        value: "WaitingForInformation",
        label: t("accountHolder.verificationStatus.waitingForInformation"),
      },
      { value: "Pending", label: t("accountHolder.verificationStatus.pending") },
      { value: "Verified", label: t("accountHolder.verificationStatus.verified") },
      { value: "Refused", label: t("accountHolder.verificationStatus.refused") },
    ],
  }),
};

type AccountHolderFilters = FiltersState<typeof filtersDefinition>;

type FiltersFormProps = {
  filters: AccountHolderFilters;
  columns: ColumnChooserConfig<Edge, ExtraInfo>;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: AccountHolderFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
  openExportDataModal: () => void;
  membershipEmail: string | undefined;
};

const FiltersForm = ({
  filters,
  columns,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
  openExportDataModal,
  membershipEmail,
}: FiltersFormProps) => {
  const filtersProps = useFiltersProps({ filtersDefinition, filters });
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser {...filtersProps.chooser} />
        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        {isNotNullish(membershipEmail) && (
          <>
            <LakeTooltip placement="center" content={t("dataExport.exportData")}>
              <TrackPressable action="Export user data">
                <LakeButton
                  mode="secondary"
                  size="small"
                  onPress={openExportDataModal}
                  icon="arrow-download-filled"
                  ariaLabel={t("dataExport.exportData")}
                />
              </TrackPressable>
            </LakeTooltip>

            <Space width={8} />
          </>
        )}

        <TrackPressable action="Refresh account holders list">
          <LakeButton
            ariaLabel={t("common.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />
      <FiltersStack {...filtersProps.stack} onChangeFilters={onChangeFilters} />
    </>
  );
};
