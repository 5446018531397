import { Array, AsyncData, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Toggle } from "@swan-io/lake/src/components/Toggle";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import {
  filter,
  FiltersStack,
  FiltersState,
  useFiltersProps,
} from "@swan-io/shared-business/src/components/Filters";
import { useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { match, P } from "ts-pattern";
import { CardType, UserCardFragment, UserCardsDocument } from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { RouteParams, Router } from "../utils/routes";
import { CardSpendingLimitCell, CardStatusCell, FullNameAndCardTypeCell } from "./CardListCells";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";

const styles = StyleSheet.create({
  endFilters: {
    flexGrow: 0,
    flexShrink: 1,
  },
});

export const ACTIVE_STATUSES = [
  "Processing" as const,
  "Enabled" as const,
  "ConsentPending" as const,
];
const CANCELED_STATUSES = ["Canceling" as const, "Canceled" as const];

const PER_PAGE = 20;

type ExtraInfo = { projectEnv: ProjectEnv; projectId: string };

const keyExtractor = (node: UserCardFragment) => node.id;

const getRowLink = ({
  item: { id },
  extraInfo: { projectEnv, projectId },
}: LinkConfig<UserCardFragment, ExtraInfo>) => (
  <Link to={Router.CardDetailRoot({ projectId, projectEnv, cardId: id })} />
);

const defaultActiveColumns: ColumnConfig<UserCardFragment, ExtraInfo>[] = [
  {
    id: "type",
    width: 350,
    title: t("cards.list.card"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => <FullNameAndCardTypeCell card={item} />,
  },
  {
    id: "name",
    width: 250,
    title: t("cards.list.name"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => <TextCell color={colors.gray[600]} text={item.name ?? "-"} />,
  },
  {
    id: "status",
    width: 200,
    title: t("cards.list.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => <CardStatusCell card={item} />,
  },
  {
    id: "spendingLimit",
    width: 250,
    title: t("user.details.cards.table.spendingLimit"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => <CardSpendingLimitCell card={item} />,
  },
];

type Props = {
  params: RouteParams<"UserDetailCards">;
};

export const UserDetailCards = ({ params }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();

  const filters = useMemo((): CardFilters => {
    return {
      types:
        params.types != null
          ? Array.filterMap(params.types, item =>
              match(item)
                .with("Virtual", "VirtualAndPhysical", "SingleUseVirtual", Option.Some)
                .otherwise(Option.None),
            )
          : undefined,
    } as const;
  }, [params]);

  const status = params.status === "Canceled" ? "Canceled" : "Active";

  const hasFilters = Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(UserCardsDocument, {
    first: PER_PAGE,
    filters: {
      userId: params.userId,
      ...filters,
      statuses: status === "Active" ? ACTIVE_STATUSES : CANCELED_STATUSES,
    },
  });

  const columns = useColumnChooser("User>Cards", {
    defaultActiveColumns,
  });

  const extraInfo = useMemo(
    () => ({
      projectEnv,
      projectId,
    }),
    [projectEnv, projectId],
  );

  return (
    <>
      <FiltersForm
        filters={filters}
        columns={columns}
        status={status}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("UserDetailCards", { ...params, ...filters });
        }}
        onChangeStatus={status => {
          Router.replace("UserDetailCards", { ...params, status });
        }}
      />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={104}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.cards}>
            {cards => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={cards.edges.map(edge => edge.node) ?? []}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                getRowLink={getRowLink}
                columns={columns.active}
                onEndReached={() => {
                  if (cards?.pageInfo.hasNextPage === true) {
                    setVariables({ after: cards?.pageInfo.endCursor ?? undefined });
                  }
                }}
                headerHeight={48}
                rowHeight={104}
                loading={{ isLoading, count: PER_PAGE }}
                renderEmptyList={() =>
                  hasFilters ? (
                    <EmptyView
                      icon="clipboard-search-regular"
                      title={t("common.list.noResults")}
                      subtitle={t("common.list.noResultsSuggestion")}
                    />
                  ) : (
                    <EmptyView icon="lake-inbox-empty" title={t("common.list.noResults")} />
                  )
                }
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};

const filtersDefinition = {
  types: filter.checkbox<CardType>({
    label: t("cards.list.type"),
    items: [
      { value: "Virtual", label: t("card.type.virtual") },
      { value: "VirtualAndPhysical", label: t("card.type.virtualAndPhysical") },
      { value: "SingleUseVirtual", label: t("card.type.singleUseVirtual") },
    ],
  }),
};

export type CardFilters = FiltersState<typeof filtersDefinition>;

type FiltersFormProps = {
  filters: CardFilters;
  columns: ColumnChooserConfig<UserCardFragment, ExtraInfo>;
  status: "Active" | "Canceled";
  onChangeFilters: (filters: Partial<CardFilters>) => void;
  onRefresh: () => Future<unknown>;
  onChangeStatus: (status: "Active" | "Canceled") => void;
};

const FiltersForm = ({
  filters,
  columns,
  status,
  onChangeFilters,
  onRefresh,
  onChangeStatus,
}: FiltersFormProps) => {
  const filtersProps = useFiltersProps({ filtersDefinition, filters });
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser {...filtersProps.chooser} />
        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        <LakeButton
          ariaLabel={t("common.refresh")}
          mode="secondary"
          size="small"
          icon="arrow-counterclockwise-filled"
          loading={isRefreshing}
          onPress={() => {
            setIsRefreshing(true);
            onRefresh().tap(() => setIsRefreshing(false));
          }}
        />

        <Fill minWidth={16} />

        <Box direction="row" alignItems="center" justifyContent="end" style={styles.endFilters}>
          <Toggle
            value={status === "Active"}
            onToggle={status => onChangeStatus(status ? "Active" : "Canceled")}
            onLabel={t("cards.list.status.Active")}
            offLabel={t("cards.list.status.Canceled")}
          />
        </Box>
      </Box>

      <Space height={12} />
      <FiltersStack {...filtersProps.stack} onChangeFilters={onChangeFilters} />
    </>
  );
};
