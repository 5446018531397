import { Option } from "@swan-io/boxed";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { trim } from "@swan-io/lake/src/utils/string";
import { badStatusToError, Request } from "@swan-io/request";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import dayjs from "dayjs";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { t } from "../utils/i18n";
import { validateRequired } from "../utils/validations";

const styles = StyleSheet.create({
  input: {
    height: 200,
  },
});

type Props = {
  email: string | undefined;
  projectName: string | undefined;
  visible: boolean;
  onPressClose: () => void;
};

export const FeedbackModal = ({ email, projectName, visible, onPressClose }: Props) => {
  const { Field, formStatus, submitForm, resetForm } = useForm({
    message: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: trim,
      validate: validateRequired,
    },
  });

  const close = useCallback(() => {
    onPressClose();
    resetForm();
  }, [onPressClose, resetForm]);

  const submit = useCallback(() => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const { message } = option.get();
          const sourceUrl = window.location.origin + window.location.pathname; // strip search params
          const title = `${projectName} - ${dayjs(Date.now()).format("YYYY/MM/DD")}`;

          return Request.make({
            url: "/api/feedback",
            method: "POST",
            type: "json",
            body: JSON.stringify({
              title,
              message,
              email,
              sourceUrl,
            }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
            .mapOkToResult(badStatusToError)
            .tapOk(() => {
              close();
              showToast({ variant: "success", title: t("feedbackModal.toast") });
            })
            .tapError(error => {
              showToast({ variant: "error", title: translateError(error) });
            });
        }
      },
    });
  }, [email, projectName, submitForm, close]);

  return (
    <LakeModal
      visible={visible}
      maxWidth={700}
      icon="comment-regular"
      title={t("feedbackModal.title")}
      onPressClose={close}
    >
      <LakeText>{t("feedbackModal.description")}</LakeText>
      <Space height={16} />

      <Field name="message">
        {({ onChange, value, ref, error, onBlur }) => (
          <LakeTextInput
            ref={ref}
            placeholder={t("feedbackModal.placeholder")}
            error={error}
            multiline={true}
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            style={styles.input}
          />
        )}
      </Field>

      <LakeButtonGroup paddingBottom={0}>
        <LakeButton color="gray" mode="secondary" grow={true} onPress={close}>
          {t("common.cancel")}
        </LakeButton>

        <LakeButton
          color="current"
          grow={true}
          onPress={submit}
          loading={formStatus === "submitting"}
        >
          {t("common.submit")}
        </LakeButton>
      </LakeButtonGroup>
    </LakeModal>
  );
};
