import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import {
  LinkConfig,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { identity } from "@swan-io/lake/src/utils/function";
import { isNotNullish, nullishOrEmptyToUndefined } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import { useCallback, useMemo } from "react";
import { match, P } from "ts-pattern";
import {
  AccountMembershipListFragment,
  AccountMembershipOrderByFieldInput,
  AccountMembershipOrderByInput,
  GetAccountMembershipListForAccountDocument,
  OrderByDirection,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";
import {
  AccountMembershipList,
  accountMembershipListDefaultColumns,
  AccountMembershipsFilters,
  AccountMembershipsFiltersForm,
  parseBooleanParam,
} from "./AccountMembershipList";
import { useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";

type Props = {
  accountId: string;
  params: RouteParams<"AccountDetailAccountMemberships">;
};

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: AccountMembershipOrderByInput) => void;
  sortBy?: AccountMembershipOrderByInput;
  reexecuteQuery: () => void;
};

const PER_PAGE = 20;

const statusList = [
  "BindingUserError",
  "ConsentPending",
  "Disabled",
  "Enabled",
  "InvitationSent",
  "Suspended",
] as const;

export const AccountDetailAccountMemberships = ({ accountId, params }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const filters = useMemo((): AccountMembershipsFilters => {
    const { status } = params;

    return {
      status: isNotNullish(status)
        ? Array.filterMap(status, item =>
            match(item)
              .with(...statusList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      canViewAccount: parseBooleanParam(params.canViewAccount),
      canManageCards: parseBooleanParam(params.canManageCards),
      canInitiatePayments: parseBooleanParam(params.canInitiatePayments),
      canManageAccountMembership: parseBooleanParam(params.canManageAccountMembership),
      canManageBeneficiaries: parseBooleanParam(params.canManageBeneficiaries),
    } as const;
  }, [params]);

  const sortBy: AccountMembershipOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<AccountMembershipOrderByFieldInput>()
        .with("createdAt", "updatedAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const search = nullishOrEmptyToUndefined(params.search?.trim());
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(
    GetAccountMembershipListForAccountDocument,
    {
      accountId,
      first: PER_PAGE,
      filters: { ...filters, search },
      orderBy: sortBy,
    },
  );

  const columns = useColumnChooser("Account>Memberships", {
    defaultFixedColumns: accountMembershipListDefaultColumns.fixed,
    defaultActiveColumns: accountMembershipListDefaultColumns.active,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("AccountDetailAccountMemberships", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
      reexecuteQuery: reload,
    };
  }, [projectEnv, projectId, reload, sortBy, params]);

  const getRowLink = useCallback(
    ({
      item: {
        node: { id },
      },
      extraInfo: { projectEnv, projectId },
    }: LinkConfig<GetEdge<AccountMembershipListFragment>, ExtraInfo>) => (
      <Link
        to={Router.AccountMembershipsDetailRoot({
          projectId,
          projectEnv,
          accountMembershipId: id,
        })}
      />
    ),
    [],
  );

  const memberships = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ account }) => Option.fromNullable(account?.memberships));

  const totalCount = memberships.map(({ totalCount }) => totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <AccountMembershipsFiltersForm
        filters={filters}
        columns={columns}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("AccountDetailAccountMemberships", {
            ...params,
            ...filters,
            canViewAccount: String(filters.canViewAccount),
            canManageCards: String(filters.canManageCards),
            canInitiatePayments: String(filters.canInitiatePayments),
            canManageAccountMembership: String(filters.canManageAccountMembership),
            canManageBeneficiaries: String(filters.canManageBeneficiaries),
          });
        }}
        onChangeSearch={search => {
          Router.replace("AccountDetailAccountMemberships", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.account?.memberships}>
            {memberships => (
              <AccountMembershipList
                accountMemberships={memberships?.edges ?? []}
                columns={columns}
                onEndReached={() => {
                  if (memberships?.pageInfo.hasNextPage === true) {
                    setVariables({ after: memberships?.pageInfo.endCursor ?? undefined });
                  }
                }}
                isLoading={isLoading}
                perPage={PER_PAGE}
                extraInfo={extraInfo}
                emptyListTitle={t("accountsMemberships.list.empty")}
                getRowLink={getRowLink}
                hasSearchOrFilters={hasSearchOrFilters}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
