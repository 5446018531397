import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { useMemo } from "react";
import { t } from "../utils/i18n";
import { Router, simulatorTopLevelRoutes } from "../utils/routes";
import { useProjectInfo } from "./useProjectInfo";

export const useSimulatorSectionCrumb = () => {
  const { projectId } = useProjectInfo();
  const route = Router.useRoute(simulatorTopLevelRoutes);

  const siblings = useMemo(
    () => [
      {
        url: Router.SandboxDevelopersSimulatorSepaCreditTransferReceptionIn({ projectId }),
        isMatching: route?.name === "SandboxDevelopersSimulatorSepaCreditTransferArea",
        label: t("simulatorPage.SCT"),
      },
      {
        url: Router.SandboxDevelopersSimulatorSepaDirectDebitReceptionOut({ projectId }),
        isMatching: route?.name === "SandboxDevelopersSimulatorSepaDirectDebitArea",
        label: t("simulatorPage.SDD"),
      },
      {
        url: Router.SandboxDevelopersSimulatorInternationalCreditTransferBooking({ projectId }),
        isMatching: route?.name === "SandboxDevelopersSimulatorInternationalCreditTransferArea",
        label: t("simulatorPage.ICT"),
      },
      {
        url: Router.SandboxDevelopersSimulatorCardAuthorizationOut({ projectId }),
        isMatching: route?.name === "SandboxDevelopersSimulatorCardArea",
        label: t("simulatorPage.card"),
      },
      {
        url: Router.SandboxDevelopersSimulatorXPaySimulateDigitalization({ projectId }),
        isMatching: route?.name === "SandboxDevelopersSimulatorXPayArea",
        label: t("simulatorPage.xPay"),
      },
      {
        url: Router.SandboxDevelopersSimulatorAccountHolderUpdateVerificationStatus({
          projectId,
        }),
        isMatching: route?.name === "SandboxDevelopersSimulatorAccountHolderArea",
        label: t("simulatorPage.accountHolder"),
      },
      {
        url: Router.SandboxDevelopersSimulatorSupportingDocumentCollectionRequestSupportingDocuments(
          { projectId },
        ),
        isMatching: route?.name === "SandboxDevelopersSimulatorSupportingDocumentCollectionArea",
        label: t("simulatorPage.supportingDocumentCollection"),
      },
      {
        url: Router.SandboxDevelopersSimulatorMerchantProfilesUpdateProfileStatus({ projectId }),
        isMatching: route?.name === "SandboxDevelopersSimulatorMerchantProfilesArea",
        label: t("simulatorPage.merchantProfiles"),
      },
      {
        url: Router.SandboxDevelopersSimulatorMerchantPaymentsRoot({ projectId }),
        isMatching: route?.name === "SandboxDevelopersSimulatorMerchantPaymentsArea",
        label: t("simulatorPage.merchantPayments"),
      },
      {
        url: Router.SandboxDevelopersSimulatorBillingGenerateInvoice({ projectId }),
        isMatching: route?.name === "SandboxDevelopersSimulatorBillingArea",
        label: t("simulatorPage.billing"),
      },
      {
        url: Router.SandboxDevelopersSimulatorCapitalDepositValidate({ projectId }),
        isMatching: route?.name === "SandboxDevelopersSimulatorCapitalDepositArea",
        label: t("simulatorPage.capitalDeposit"),
      },
    ],
    [projectId, route?.name],
  );

  useCrumb(
    useMemo(() => {
      const item = siblings.find(item => item.isMatching);
      if (item == null) {
        return undefined;
      }
      return {
        label: item.label,
        link: item.url,
        siblings,
      };
    }, [siblings]),
  );
};
