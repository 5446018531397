import { Array, AsyncData, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  LinkConfig,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { deriveUnion, identity } from "@swan-io/lake/src/utils/function";
import {
  emptyToUndefined,
  isNotNullish,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  FiltersStack,
  FiltersState,
  filter,
  useFiltersProps,
} from "@swan-io/shared-business/src/components/Filters";
import { useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import { P, match } from "ts-pattern";
import {
  GetAccountTransactionsDocument,
  OrderByDirection,
  PaymentProduct,
  TransactionListFragment,
  TransactionStatus,
  TransactionTypeEnum,
  TransactionsOrderByField,
  TransactionsOrderByInput,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";
import { transactionTypes } from "../utils/transactions";
import {
  isAfterUpdatedAtSelectable,
  isBeforeUpdatedAtSelectable,
  validateAfterUpdatedAt,
  validateBeforeUpdatedAt,
} from "../utils/validations";
import {
  AccountDetailTransactionList,
  ExtraInfo,
  accountDetailTransactionListDefaultColumns,
} from "./AccountDetailTransactionList";
import { useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

type Props = {
  accountId: string;
  params: RouteParams<"AccountDetailTransactions">;
};

const paymentProducts = deriveUnion<PaymentProduct>({
  Card: true,
  Check: true,
  InternalCreditTransfer: true,
  InternationalCreditTransfer: true,
  InternalDirectDebit: true,
  SEPACreditTransfer: true,
  SEPADirectDebit: true,
  Fees: true,
});

const statuses = ["Booked", "Canceled", "Pending", "Rejected", "Released", "Upcoming"] as const;

const filtersDefinition = {
  isAfterUpdatedAt: filter.date({
    label: t("transaction.filters.isAfterUpdatedAt.label"),
    isSelectable: isAfterUpdatedAtSelectable,
    validate: validateAfterUpdatedAt,
  }),
  isBeforeUpdatedAt: filter.date({
    label: t("transaction.filters.isBeforeUpdatedAt.label"),
    isSelectable: isBeforeUpdatedAtSelectable,
    validate: validateBeforeUpdatedAt,
  }),
  paymentProduct: filter.checkbox<PaymentProduct>({
    label: t("transaction.filters.paymentProduct.label"),
    items: paymentProducts.array.map(value => ({ label: value, value })),
  }),
  status: filter.checkbox<TransactionStatus>({
    label: t("transaction.filters.status.label"),
    items: statuses.map(value => ({ label: value, value })),
  }),
  type: filter.checkbox<TransactionTypeEnum>({
    label: t("transaction.filters.type.label"),
    items: transactionTypes.array.map(value => ({ label: value, value })),
  }),
};

type TransactionsFilters = FiltersState<typeof filtersDefinition>;

type FiltersFormProps = {
  filters: TransactionsFilters;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: TransactionsFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
};

const FiltersForm = ({
  filters,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
}: FiltersFormProps) => {
  const filtersProps = useFiltersProps({ filtersDefinition, filters });
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <View>
      <Box direction="row" alignItems="center">
        <FilterChooser {...filtersProps.chooser} />
        <Space width={16} />

        <TrackPressable action="Refresh transactions list">
          <LakeButton
            ariaLabel={t("webhook.table.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />
      <FiltersStack {...filtersProps.stack} onChangeFilters={onChangeFilters} />
    </View>
  );
};

const PER_PAGE = 20;

export const AccountDetailTransactions = ({ accountId, params }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const filters = useMemo((): TransactionsFilters => {
    const { paymentProduct, status, type } = params;

    return {
      isAfterUpdatedAt: params.isAfterUpdatedAt,
      isBeforeUpdatedAt: params.isBeforeUpdatedAt,
      paymentProduct: isNotNullish(paymentProduct)
        ? Array.filterMap(paymentProduct, item =>
            match(item)
              .with(paymentProducts.P, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      status: isNotNullish(status)
        ? Array.filterMap(status, item =>
            match(item)
              .with(...statuses, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      type: isNotNullish(type)
        ? Array.filterMap(type, item =>
            transactionTypes.is(item) ? Option.Some(item) : Option.None(),
          )
        : undefined,
    } as const;
  }, [params]);

  const sortBy: TransactionsOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<TransactionsOrderByField>()
        .with("executionDate", "id", "createdAt", "updatedAt", identity)
        .otherwise(() => "executionDate"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const search = nullishOrEmptyToUndefined(params.search?.trim());
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountTransactionsDocument, {
    accountId,
    first: PER_PAGE,
    filters: { ...filters, search },
    orderBy: sortBy,
  });

  const columns = useColumnChooser("Account>Transactions", {
    defaultFixedColumns: accountDetailTransactionListDefaultColumns.fixed,
    defaultActiveColumns: accountDetailTransactionListDefaultColumns.active,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("AccountDetailTransactions", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
      reexecuteQuery: reload,
    };
  }, [projectEnv, projectId, reload, sortBy, params]);

  const getRowLink = useCallback(
    ({
      item: {
        node: { id },
      },
      extraInfo: { projectEnv, projectId },
    }: LinkConfig<GetEdge<TransactionListFragment>, ExtraInfo>) => (
      <Link
        to={Router.AccountDetailTransactionsDetail({
          projectId,
          projectEnv,
          accountId,
          transactionId: id,
        })}
      />
    ),
    [accountId],
  );

  const transactions = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ account }) => Option.fromNullable(account?.transactions));

  const totalCount = transactions.map(({ totalCount }) => totalCount);
  const pageInfo = transactions.map(({ pageInfo }) => pageInfo);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <FiltersForm
        totalCount={totalCount}
        filters={filters}
        search={search}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("AccountDetailTransactions", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("AccountDetailTransactions", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.account?.transactions}>
            {transactions => (
              <AccountDetailTransactionList
                transactions={transactions?.edges ?? []}
                columns={columns}
                onEndReached={() => {
                  if (pageInfo.isSome() && pageInfo.get()?.hasNextPage === true) {
                    setVariables({ after: pageInfo.get()?.endCursor ?? undefined });
                  }
                }}
                isLoading={isLoading}
                perPage={PER_PAGE}
                extraInfo={extraInfo}
                emptyListTitle={t("account.transactions.empty", {
                  name: data.account?.name ?? accountId,
                })}
                getRowLink={getRowLink}
                hasSearchOrFilters={hasSearchOrFilters}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
