import { AsyncData, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { Cell, CopyableTextCell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  filter,
  FiltersStack,
  FiltersState,
  useFiltersProps,
} from "@swan-io/shared-business/src/components/Filters";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { isMatching, match, P } from "ts-pattern";
import {
  GetPaymentsFromMerchantProfileDocument,
  GetPaymentsFromMerchantProfileQuery,
  MerchantPaymentMethodType,
  MerchantPaymentStatus,
} from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { formatCurrency, locale, t } from "../utils/i18n";
import { RouteParams, Router } from "../utils/routes";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

const PER_PAGE = 20;

type Edge = GetEdge<
  NonNullable<
    NonNullable<GetPaymentsFromMerchantProfileQuery["merchantProfile"]>["merchantPayments"]
  >
>;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
};

const filtersDefinition = {
  status: filter.checkbox<MerchantPaymentStatus>({
    label: t("merchantPayment.status"),
    items: [
      { value: "Authorized", label: t("merchantPayment.status.authorized") },
      { value: "Rejected", label: t("merchantPayment.status.rejected") },
      { value: "Canceled", label: t("merchantPayment.status.canceled") },
      { value: "Captured", label: t("merchantPayment.status.captured") },
      { value: "Initiated", label: t("merchantPayment.status.initiated") },
    ],
  }),
  paymentMethods: filter.checkbox<MerchantPaymentMethodType>({
    label: t("merchantPayment.paymentMethodType"),
    items: [
      { value: "Card", label: t("merchantPaymentMethods.card") },
      { value: "Check", label: t("merchantPaymentMethods.check") },
      {
        value: "InternalDirectDebitB2b",
        label: t("merchantPaymentMethods.internalDirectDebitB2b"),
      },
      {
        value: "InternalDirectDebitStandard",
        label: t("merchantPaymentMethods.internalDirectDebitStandard"),
      },
      { value: "SepaDirectDebitB2b", label: t("merchantPaymentMethods.sepaDirectDebitB2b") },
      { value: "SepaDirectDebitCore", label: t("merchantPaymentMethods.sepaDirectDebitCore") },
    ],
  }),
};

type MerchantPaymentFilters = FiltersState<typeof filtersDefinition>;

const keyExtractor = ({ node: { id } }: Edge) => id;

const defaultFixedColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    id: "amount",
    width: 150,
    title: t("merchantPayment.processed"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { amount },
      },
    }) => <TextCell text={formatCurrency(Number(amount.value), amount.currency)} />,
  },
];

const defaultActiveColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 200,
    id: "paymentType",
    title: t("merchantPayment.paymentMethodType"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          paymentMethod: { type },
        },
      },
    }) => (
      <Cell>
        {match(type)
          .with("Card", () => <Tag color="darkPink">{t("merchantPaymentMethods.card")}</Tag>)
          .with("Check", () => <Tag color="shakespear">{t("merchantPaymentMethods.check")}</Tag>)
          .with("InternalDirectDebitB2b", () => (
            <Tag color="mediumSladeBlue">{t("merchantPaymentMethods.internalDirectDebitB2b")}</Tag>
          ))
          .with("InternalDirectDebitStandard", () => (
            <Tag color="mediumSladeBlue">
              {t("merchantPaymentMethods.internalDirectDebitStandard")}
            </Tag>
          ))
          .with("SepaDirectDebitB2b", () => (
            <Tag color="live">{t("merchantPaymentMethods.sepaDirectDebitB2b")}</Tag>
          ))
          .with("SepaDirectDebitCore", () => (
            <Tag color="live">{t("merchantPaymentMethods.sepaDirectDebitCore")}</Tag>
          ))
          .otherwise(() => null)}
      </Cell>
    ),
  },
  {
    width: 250,
    id: "id",
    title: t("merchantPayment.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    id: "merchantName",
    width: 200,
    title: t("merchantPayment.merchantName"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          merchantProfile: { merchantName },
        },
      },
    }) => <TextCell text={merchantName} />,
  },
  {
    id: "merchantId",
    width: 250,
    title: t("merchantPayment.merchantId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          merchantProfile: { id },
        },
      },
    }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    id: "label",
    width: 200,
    title: t("merchantPayment.label"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { label },
      },
    }) => <TextCell text={label ?? "-"} />,
  },
  {
    id: "executionDate",
    width: 200,
    title: t("merchantPayment.creationDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
  {
    width: 150,
    id: "status",
    title: t("merchantPayment.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { statusInfo },
      },
    }) => (
      <Cell>
        {match(statusInfo)
          .with({ __typename: "MerchantPaymentAuthorized" }, () => (
            <Tag color="shakespear">{t("merchantPayment.status.authorized")}</Tag>
          ))
          .with({ __typename: "MerchantPaymentCaptured" }, () => (
            <Tag color="positive">{t("merchantPayment.status.captured")}</Tag>
          ))
          .with({ __typename: "MerchantPaymentInitiated" }, () => (
            <Tag color="swan">{t("merchantPayment.status.initiated")}</Tag>
          ))
          .with({ __typename: "MerchantPaymentRejected" }, () => (
            <Tag color="negative">{t("merchantPayment.status.rejected")}</Tag>
          ))
          .with({ __typename: "MerchantPaymentCanceled" }, () => (
            <Tag color="warning">{t("merchantPayment.status.canceled")}</Tag>
          ))
          .otherwise(() => null)}
      </Cell>
    ),
  },
];

type Props = {
  params: RouteParams<"AccountDetailMerchantProfilePaymentList">;
};

export const AccountDetailMerchantProfilePaymentList = ({ params }: Props) => {
  const { accountId, merchantProfileId, projectEnv, projectId } = params;

  useCrumb(
    useMemo(
      () => ({
        label: t("merchantProfile.tab.payments"),
        link: Router.AccountDetailMerchantProfileRoot({
          projectId,
          projectEnv,
          accountId,
          merchantProfileId,
        }),
      }),
      [projectEnv, projectId, accountId, merchantProfileId],
    ),
  );

  const filters = useMemo((): MerchantPaymentFilters => {
    return {
      status: params.status?.filter(
        isMatching(P.union("Authorized", "Rejected", "Canceled", "Captured", "Initiated")),
      ),
      paymentMethods: params.paymentMethods?.filter(
        isMatching(
          P.union(
            "Card",
            "Check",
            "InternalDirectDebitB2b",
            "InternalDirectDebitStandard",
            "SepaDirectDebitB2b",
            "SepaDirectDebitCore",
          ),
        ),
      ),
    } as const;
  }, [params.paymentMethods, params.status]);

  const [data, { isLoading, setVariables, reload }] = useQuery(
    GetPaymentsFromMerchantProfileDocument,
    {
      first: PER_PAGE,
      merchantProfileId,
      filters: {
        paymentMethod: filters.paymentMethods,
        status: filters.status,
      },
    },
  );

  const columns = useColumnChooser("MerchantPayments", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return { projectEnv, projectId };
  }, [projectEnv, projectId]);

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ merchantProfile }) => merchantProfile?.merchantPayments?.totalCount ?? 0);

  const getRowLink = useCallback(
    ({ item }: LinkConfig<Edge, ExtraInfo>) => (
      <Link
        to={Router.AccountDetailMerchantProfilePaymentDetail({
          accountId,
          projectEnv,
          projectId,
          paymentId: item.node.id,
          merchantProfileId: item.node.merchantProfile.id,
        })}
      />
    ),
    [projectEnv, projectId, accountId],
  );

  return (
    <>
      <FiltersForm
        filters={filters}
        columns={columns}
        totalCount={totalCount}
        onRefresh={() => reload()}
        onChangeFilters={filters => {
          Router.replace("AccountDetailMerchantProfilePaymentList", {
            accountId,
            merchantProfileId,
            projectEnv,
            projectId,
            status: filters.status,
            paymentMethods: filters.paymentMethods,
          });
        }}
      />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={PER_PAGE}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.merchantProfile?.merchantPayments}>
            {merchantPayments => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={merchantPayments?.edges ?? []}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                stickedToStartColumns={columns.fixed}
                columns={columns.active}
                headerHeight={48}
                rowHeight={48}
                getRowLink={getRowLink}
                loading={{
                  isLoading,
                  count: PER_PAGE,
                }}
                onEndReached={() => {
                  if (merchantPayments?.pageInfo.hasNextPage === true) {
                    setVariables({
                      after: merchantPayments.pageInfo.endCursor ?? undefined,
                    });
                  }
                }}
                renderEmptyList={() => (
                  <EmptyView
                    icon="lake-inbox-empty"
                    title={t("merchantPayments.list.empty.title")}
                  />
                )}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};

type FiltersFormProps = {
  filters: MerchantPaymentFilters;
  columns: ColumnChooserConfig<Edge, ExtraInfo>;
  totalCount: Option<number>;
  onChangeFilters: (filters: MerchantPaymentFilters) => void;
  onRefresh: () => Future<unknown>;
};

const FiltersForm = ({ filters, columns, onChangeFilters, onRefresh }: FiltersFormProps) => {
  const filtersProps = useFiltersProps({ filtersDefinition, filters });
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center" justifyContent="start">
        <FilterChooser {...filtersProps.chooser} />
        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        <TrackPressable action="Refresh account details merchant payments list">
          <LakeButton
            ariaLabel={t("common.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>
      </Box>

      <Space height={12} />
      <FiltersStack {...filtersProps.stack} onChangeFilters={onChangeFilters} />
    </>
  );
};
