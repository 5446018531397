import { ClientContext } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, BreadcrumbsRoot } from "@swan-io/lake/src/components/Breadcrumbs";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { ReactNode, useMemo } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { useSimulatorSectionCrumb } from "../hooks/useSimulatorSectionCrumb";
import { NotFoundPage } from "../pages/NotFoundPage";
import { BillingChargeManualBankingFeeSimulatorPage } from "../pages/simulator/BillingChargeManualBankingFee";
import { BillingGenerateInvoiceSimulatorPage } from "../pages/simulator/BillingGenerateInvoiceSimulatorPage";
import { CardCreditReverseSimulatorPage } from "../pages/simulator/CardCreditReverseSimulatorPage";
import { CardDebitReverseSimulatorPage } from "../pages/simulator/CardDebitReverseSimulatorPage";
import { CardOutAdviceSimulatorPage } from "../pages/simulator/CardOutAdviceSimulatorPage";
import { CardOutAuthorizationReleaseSimulatorPage } from "../pages/simulator/CardOutAuthorizationReleaseSimulatorPage";
import { CardOutAuthorizationSimulatorPage } from "../pages/simulator/CardOutAuthorizationSimulatorPage";
import { CardOutCreditSimulatorPage } from "../pages/simulator/CardOutCreditSimulatorPage";
import { CardOutDebitSimulatorPage } from "../pages/simulator/CardOutDebitSimulatorPage";
import { CardTransactionEnrichmentPage } from "../pages/simulator/CardTransactionEnrichmentPage";
import { CreateSandboxUserIdentification } from "../pages/simulator/CreateSandboxUserIdentificationPage";
import { IctBookingSimulatorPage } from "../pages/simulator/IctBookingSimulatorPage";
import { IctReceptionSimulatorPage } from "../pages/simulator/IctReceptionSimulatorPage";
import { IctRejectionSimulatorPage } from "../pages/simulator/IctRejectionSimulatorPage";
import { MerchantPaymentsArea } from "../pages/simulator/MerchantPaymentsArea";
import { PhysicalCardDeliverySimulatorPage } from "../pages/simulator/PhysicalCardDeliverySimulatorPage";
import { PhysicalCardReceptionSimulatorPage } from "../pages/simulator/PhysicalCardReceptionSimulatorPage";
import { PhysicalCardRenewalSimulatorPage } from "../pages/simulator/PhysicalCardRenewalSimulatorPage";
import { PromoteAccountHolderToProjectOwnerPage } from "../pages/simulator/PromoteAccountHolderToProjectOwnerPage";
import { RequestSupportingDocumentsPage } from "../pages/simulator/RequestSupportingDocumentsPage";
import { SctInRecallSimulatorPage } from "../pages/simulator/SctInRecallSimulatorPage";
import { SctInReceptionSimulatorPage } from "../pages/simulator/SctInReceptionSimulatorPage";
import { SctInReturnSimulatorPage } from "../pages/simulator/SctInReturnSimulatorPage";
import { SctOutBookingSimulatorPage } from "../pages/simulator/SctOutBookingSimulatorPage";
import { SctOutRecallSimulatorPage } from "../pages/simulator/SctOutRecallSimulatorPage";
import { SctOutRejectionSimulatorPage } from "../pages/simulator/SctOutRejectionSimulatorPage";
import { SctOutReturnSimulatorPage } from "../pages/simulator/SctOutReturnSimulatorPage";
import { SddOutCancelSimulatorPage } from "../pages/simulator/SddOutCancelSimulatorPage";
import { SddOutReceptionSimulatorPage } from "../pages/simulator/SddOutReceptionSimulatorPage";
import { SddOutRejectSimulatorPage } from "../pages/simulator/SddOutRejectSimulatorPage";
import { SddOutReturnSimulatorPage } from "../pages/simulator/SddOutReturnSimulatorPage";
import { SddOutReverseSimulatorPage } from "../pages/simulator/SddOutReverseSimulatorPage";
import { UpdateAccountHolderPage } from "../pages/simulator/UpdateAccountHolderSimulatorPage";
import { UpdateCapitalDepositDocumentStatusPage } from "../pages/simulator/UpdateCapitalDepositDocumentStatusPage";
import { UpdateMerchantPaymentMethodPage } from "../pages/simulator/UpdateMerchantPaymentMethodPage";
import { UpdateMerchantProfileStatusPage } from "../pages/simulator/UpdateMerchantProfileStatusPage";
import { UpdateSandboxUserIdentification } from "../pages/simulator/UpdateSandboxUserIdentificationPage";
import { UpdateSupportingDocumentCollectionStatusPage } from "../pages/simulator/UpdateSupportingDocumentCollectionStatusPage";
import { UpdateSupportingDocumentStatusPage } from "../pages/simulator/UpdateSupportingDocumentStatusPage";
import { ValidateCapitalDepositPage } from "../pages/simulator/ValidateCapitalDepositPage";
import { XPayDigitalizationSimulatorPage } from "../pages/simulator/XPayDigitalizationSimulatorPage";
import { XPayFinishInAppSimulatorPage } from "../pages/simulator/XPayFinishInAppSimulatorPage";
import {
  sandboxPartnerAdminByProjectIdClient,
  sandboxPartnerAdminByProjectIdClient__projectMember,
  sandboxPartnerAdminClient,
} from "../utils/gql";
import { t } from "../utils/i18n";
import { TrackingProvider } from "../utils/matomo";
import { Router, simulatorSubRoutes, simulatorTopLevelRoutes } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";
import { LakeDocumentationLink } from "./DocumentationLink";
import { SimulatorHome } from "./SimulatorHome";
import { SimulatorTabs } from "./SimulatorTabs";

const styles = StyleSheet.create({
  header: { zIndex: 15 },
});

const WithCrumb = ({ children }: { children: ReactNode }) => {
  useSimulatorSectionCrumb();

  return children;
};

export const SimulatorArea = () => {
  const { projectId, projectEnv } = useProjectInfo();

  const route = Router.useRoute(simulatorTopLevelRoutes);
  const subRoute = Router.useRoute(simulatorSubRoutes);
  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("simulator.allSimulatorEvents"),
        link: Router.DevelopersSimulatorRoot({ projectId, projectEnv }),
      },
    ],
    [projectId, projectEnv],
  );

  const shouldUseProjectMemberToken = useTgglFlag("dashboardProjectMemberToken").getOr(false);

  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <Box direction="row" justifyContent="spaceBetween" alignItems="center" style={styles.header}>
        <Breadcrumbs />

        <Box direction="row" justifyContent="end" alignItems="center">
          <LakeDocumentationLink to="simulatorApi">{t("common.learnMore")}</LakeDocumentationLink>
          <Space width={16} />
          <InformationTooltip text={t("simulator.subtitle")} />
        </Box>
      </Box>

      <Space height={16} />

      <TrackingProvider category="Simulators">
        {match(route)
          .with({ name: "DevelopersSimulatorRoot" }, () => (
            <LakeScrollView>
              <SimulatorHome />
              <Space height={24} />
            </LakeScrollView>
          ))
          .otherwise(route => (
            <WithCrumb>
              {match(route)
                .with({ name: "SandboxDevelopersSimulatorSepaCreditTransferArea" }, () => {
                  return (
                    <SimulatorTabs
                      selected={subRoute?.name}
                      tabs={[
                        {
                          url: "SandboxDevelopersSimulatorSepaCreditTransferReceptionIn",
                          label: t("simulatorPage.receptionIn"),
                          render: () => <SctInReceptionSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorSepaCreditTransferReturnIn",
                          label: t("simulatorPage.returnIn"),
                          render: () => <SctInReturnSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorSepaCreditTransferRecallIn",
                          label: t("simulatorPage.recallIn"),
                          render: () => <SctInRecallSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorSepaCreditTransferBookingOut",
                          label: t("simulatorPage.bookingOut"),
                          render: () => <SctOutBookingSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorSepaCreditTransferRejectionOut",
                          label: t("simulatorPage.rejectionOut"),
                          render: () => <SctOutRejectionSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorSepaCreditTransferRecallOut",
                          label: t("simulatorPage.recallOut"),
                          render: () => <SctOutRecallSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorSepaCreditTransferReturnOut",
                          label: t("simulatorPage.returnOut"),
                          render: () => <SctOutReturnSimulatorPage />,
                        },
                      ]}
                    />
                  );
                })
                .with({ name: "SandboxDevelopersSimulatorSepaDirectDebitArea" }, () => {
                  return (
                    <SimulatorTabs
                      selected={subRoute?.name}
                      tabs={[
                        {
                          url: "SandboxDevelopersSimulatorSepaDirectDebitReceptionOut",
                          label: t("simulatorPage.receptionSddOut"),
                          render: () => <SddOutReceptionSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorSepaDirectDebitCancelOut",
                          label: t("simulatorPage.cancelSddOut"),
                          render: () => <SddOutCancelSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorSepaDirectDebitRejectOut",
                          label: t("simulatorPage.rejectSddOut"),
                          render: () => <SddOutRejectSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorSepaDirectDebitReturnOut",
                          label: t("simulatorPage.returnSddOut"),
                          render: () => <SddOutReturnSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorSepaDirectDebitReverseOut",
                          label: t("simulatorPage.reverseSddOut"),
                          render: () => <SddOutReverseSimulatorPage />,
                        },
                      ]}
                    />
                  );
                })

                .with({ name: "SandboxDevelopersSimulatorInternationalCreditTransferArea" }, () => {
                  return (
                    <SimulatorTabs
                      selected={subRoute?.name}
                      tabs={[
                        {
                          url: "SandboxDevelopersSimulatorInternationalCreditTransferBooking",
                          label: t("simulatorPage.ictBooking"),
                          render: () => <IctBookingSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorInternationalCreditTransferReception",
                          label: t("simulatorPage.ictReception"),
                          render: () => <IctReceptionSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorInternationalCreditTransferRejection",
                          label: t("simulatorPage.ictRejection"),
                          render: () => <IctRejectionSimulatorPage />,
                        },
                      ]}
                    />
                  );
                })

                .with({ name: "SandboxDevelopersSimulatorCardArea" }, () => {
                  return (
                    <SimulatorTabs
                      selected={subRoute?.name}
                      tabs={[
                        {
                          url: "SandboxDevelopersSimulatorCardAuthorizationOut",
                          label: t("simulatorPage.authorizeCardOut"),
                          render: () => <CardOutAuthorizationSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorCardAdviceOut",
                          label: t("simulatorPage.adviceCardOut"),
                          render: () => <CardOutAdviceSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorCardAuthorizationReleaseOut",
                          label: t("simulatorPage.authorizeReleaseCardOut"),
                          render: () => <CardOutAuthorizationReleaseSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorCardDebitOut",
                          label: t("simulatorPage.debitCardOut"),
                          render: () => <CardOutDebitSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorCardDebitReversal",
                          label: t("simulatorPage.reverseDebitCard"),
                          render: () => <CardDebitReverseSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorCardCreditOut",
                          label: t("simulatorPage.creditCardOut"),
                          render: () => <CardOutCreditSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorCardCreditReversal",
                          label: t("simulatorPage.reverseCreditCard"),
                          render: () => <CardCreditReverseSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorCardSimulationPhysicalCardDelivery",
                          label: t("simulatorPage.simulationPhysicalCardDelivery"),
                          render: () => <PhysicalCardDeliverySimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorCardSimulationPhysicalCardReception",
                          label: t("simulatorPage.simulationPhysicalCardReception"),
                          render: () => <PhysicalCardReceptionSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorCardSimulationPhysicalCardRenewal",
                          label: t("simulatorPage.simulationPhysicalCardRenewal"),
                          render: () => <PhysicalCardRenewalSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorCardTransactionEnrichment",
                          label: t("simulatorPage.cardTransactionEnrichment"),
                          render: () => <CardTransactionEnrichmentPage />,
                        },
                      ]}
                    />
                  );
                })
                .with({ name: "SandboxDevelopersSimulatorXPayArea" }, () => {
                  return (
                    <SimulatorTabs
                      selected={subRoute?.name}
                      tabs={[
                        {
                          url: "SandboxDevelopersSimulatorXPaySimulateDigitalization",
                          label: t("simulatorPage.xPay.simulateDigitalization"),
                          render: () => <XPayDigitalizationSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorXPayFinishInApp",
                          label: t("simulatorPage.xPay.finishInApp"),
                          render: () => <XPayFinishInAppSimulatorPage />,
                        },
                      ]}
                    />
                  );
                })
                .with({ name: "SandboxDevelopersSimulatorAccountHolderArea" }, () => {
                  return (
                    <SimulatorTabs
                      selected={subRoute?.name}
                      tabs={[
                        {
                          url: "SandboxDevelopersSimulatorAccountHolderUpdateVerificationStatus",
                          label: t("simulatorPage.accountHolder.updateVerificationStatus"),
                          render: () => <UpdateAccountHolderPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorPromoteAccountHolderToProjectOwner",
                          label: t(
                            "simulatorPage.accountHolder.promoteAccountHolderToProjectOwner",
                          ),
                          render: () => <PromoteAccountHolderToProjectOwnerPage />,
                        },
                      ]}
                    />
                  );
                })
                .with(
                  { name: "SandboxDevelopersSimulatorSupportingDocumentCollectionArea" },
                  () => {
                    return (
                      <SimulatorTabs
                        selected={subRoute?.name}
                        tabs={[
                          {
                            url: "SandboxDevelopersSimulatorSupportingDocumentCollectionUpdateStatus",
                            label: t(
                              "simulatorPage.supportingDocumentCollection.updateSupportingDocumentCollectionStatus",
                            ),
                            render: () => <UpdateSupportingDocumentCollectionStatusPage />,
                          },
                          {
                            url: "SandboxDevelopersSimulatorSupportingDocumentCollectionUpdateSupportingDocumentStatus",
                            label: t(
                              "simulatorPage.supportingDocumentCollection.updateSupportingDocumentStatus",
                            ),
                            render: () => <UpdateSupportingDocumentStatusPage />,
                          },
                          {
                            url: "SandboxDevelopersSimulatorSupportingDocumentCollectionRequestSupportingDocuments",
                            label: t(
                              "simulatorPage.supportingDocumentCollection.requestSupportingDocuments",
                            ),
                            render: () => <RequestSupportingDocumentsPage />,
                          },
                        ]}
                      />
                    );
                  },
                )
                .with({ name: "SandboxDevelopersSimulatorMerchantProfilesArea" }, () => {
                  return (
                    <SimulatorTabs
                      selected={subRoute?.name}
                      tabs={[
                        {
                          url: "SandboxDevelopersSimulatorMerchantProfilesUpdateProfileStatus",
                          label: t("simulatorPage.merchantProfiles.updateMerchantProfileStatus"),
                          render: () => <UpdateMerchantProfileStatusPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorMerchantProfilesUpdatePaymentProductStatus",
                          label: t(
                            "simulatorPage.merchantProfiles.updateMerchantPaymentMethodStatus",
                          ),
                          render: () => <UpdateMerchantPaymentMethodPage />,
                        },
                      ]}
                    />
                  );
                })
                .with({ name: "SandboxDevelopersSimulatorMerchantPaymentsArea" }, () => {
                  return <MerchantPaymentsArea />;
                })
                .with({ name: "SandboxDevelopersSimulatorSandboxUsersArea" }, () => {
                  return (
                    <ClientContext.Provider value={sandboxPartnerAdminClient}>
                      <SimulatorTabs
                        selected={subRoute?.name}
                        tabs={[
                          {
                            url: "SandboxDevelopersSimulatorSandboxUsersCreateIdentification",
                            label: t("simulatorPage.sandboxUsers.createIdentification"),
                            render: ({ userId }) => (
                              <CreateSandboxUserIdentification userId={userId} />
                            ),
                          },
                          {
                            url: "SandboxDevelopersSimulatorSandboxUsersUpdateIdentification",
                            label: t("simulatorPage.sandboxUsers.updateIdentification"),
                            render: () => <UpdateSandboxUserIdentification />,
                          },
                        ]}
                      />
                    </ClientContext.Provider>
                  );
                })
                .with({ name: "SandboxDevelopersSimulatorBillingArea" }, () => {
                  return (
                    <SimulatorTabs
                      selected={subRoute?.name}
                      tabs={[
                        {
                          url: "SandboxDevelopersSimulatorBillingGenerateInvoice",
                          label: t("simulatorPage.billing.generateInvoice"),
                          render: () => <BillingGenerateInvoiceSimulatorPage />,
                        },
                        {
                          url: "SandboxDevelopersSimulatorBillingChargeManualBankingFee",
                          label: t("simulatorPage.billing.chargeManualBankingFee"),
                          render: () => <BillingChargeManualBankingFeeSimulatorPage />,
                        },
                      ]}
                    />
                  );
                })
                .with(
                  { name: "SandboxDevelopersSimulatorCapitalDepositArea" },
                  ({ params: { projectId } }) => {
                    return (
                      <ClientContext.Provider
                        value={
                          shouldUseProjectMemberToken
                            ? sandboxPartnerAdminByProjectIdClient__projectMember(projectId)
                            : sandboxPartnerAdminByProjectIdClient(projectId)
                        }
                      >
                        <SimulatorTabs
                          selected={subRoute?.name}
                          tabs={[
                            {
                              url: "SandboxDevelopersSimulatorCapitalDepositValidate",
                              label: t("simulatorPage.capitalDeposit.validateCapitalDeposit"),
                              render: () => <ValidateCapitalDepositPage />,
                            },
                            {
                              url: "SandboxDevelopersSimulatorCapitalDepositUpdateDocument",
                              label: t("simulatorPage.capitalDeposit.updateDocumentStatus"),
                              render: () => <UpdateCapitalDepositDocumentStatusPage />,
                            },
                          ]}
                        />
                      </ClientContext.Provider>
                    );
                  },
                )
                .otherwise(() => (
                  <NotFoundPage />
                ))}
            </WithCrumb>
          ))}
      </TrackingProvider>
    </BreadcrumbsRoot>
  );
};
