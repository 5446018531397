import { AsyncData, Future, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, CopyableTextCell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { identity } from "@swan-io/lake/src/utils/function";
import { isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import { GetNode } from "@swan-io/lake/src/utils/types";
import {
  filter,
  FiltersStack,
  FiltersState,
  useFiltersProps,
} from "@swan-io/shared-business/src/components/Filters";
import { toOptionalValidator } from "@swan-io/use-form";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { View } from "react-native";
import { match, P } from "ts-pattern";
import {
  WebhookEventLogsDocument,
  WebhookEventLogsQuery,
  WebhookSubscriptionQuery,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { RouteParams, Router } from "../utils/routes";
import { validateDate, validateId } from "../utils/validations";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

const NUM_EVENT_LOGS_PER_PAGE = 20;

type Props = {
  webhookSubscriptionId: string;
  webhookEventTypes: string[];
  params: RouteParams<"DevelopersWebhooksSubscriptionEventLogs">;
};

type WebhookSubscription = NonNullable<WebhookSubscriptionQuery["webhookSubscription"]>;

type WebhookEvent = GetNode<NonNullable<WebhookEventLogsQuery["webhookEventLogs"]>>;

type WebhookStatus = "Success" | "Failure";

const filtersDefinition = {
  statusText: filter.radio<WebhookStatus | undefined>({
    label: t("webhook.filters.status.label"),
    items: [
      { value: undefined, label: t("webhook.filters.all") },
      { value: "Success", label: t("webhook.filters.status.succeeded") },
      { value: "Failure", label: t("webhook.filters.status.failed") },
    ],
  }),
  resourceId: filter.input({
    label: t("webhook.table.resourceId"),
    placeholder: t("webhook.table.resourceId.placeholder"),
  }),
  eventId: filter.input({
    label: t("webhook.table.eventId"),
    placeholder: t("webhook.table.id.placeholder"),
    validate: validateId,
  }),
  eventType: filter.radio<WebhookSubscription["eventTypes"][number] | undefined>({
    label: t("webhook.filters.eventType.label"),
    items: [],
  }),
  startDate: filter.date({
    label: t("webhook.filters.startDate.label"),
    validate: toOptionalValidator(validateDate),
  }),
  endDate: filter.date({
    label: t("webhook.filters.endDate.label"),
    validate: toOptionalValidator(validateDate),
  }),
};

type WebhookEventsFilters = FiltersState<typeof filtersDefinition>;

type FiltersFormProps = {
  filters: WebhookEventsFilters;
  columns: ColumnChooserConfig<Edge, ExtraInfo>;
  onChangeFilters: (filters: WebhookEventsFilters) => void;
  onRefresh: () => Future<unknown>;
  eventTypes: WebhookSubscription["eventTypes"];
};

const FiltersForm = ({
  filters,
  columns,
  onChangeFilters,
  onRefresh,
  eventTypes,
}: FiltersFormProps) => {
  // Add event types depending on webhook config
  const definitionWithEventTypes = useMemo(
    () => ({
      ...filtersDefinition,
      eventType: {
        ...filtersDefinition.eventType,
        items: [
          { value: undefined, label: t("webhook.filters.all") },
          ...eventTypes.map(eventType => ({ value: eventType, label: eventType })),
        ],
      },
    }),
    [eventTypes],
  );

  const filtersProps = useFiltersProps({ filtersDefinition: definitionWithEventTypes, filters });
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <View>
      <Box direction="row" justifyContent="start">
        <FilterChooser {...filtersProps.chooser} />
        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        <TrackPressable action="Refresh webhook events list">
          <LakeButton
            ariaLabel={t("webhook.table.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>
      </Box>

      <Space height={12} />
      <FiltersStack {...filtersProps.stack} onChangeFilters={onChangeFilters} />
    </View>
  );
};

const keyExtractor = ({ id }: WebhookEvent) => id;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  webhookSubscriptionId: string;
  reexecuteQuery: () => void;
};
type Edge = WebhookEvent;

const getRowLink = ({
  item: { id },
  extraInfo: { projectEnv, projectId, webhookSubscriptionId },
}: LinkConfig<Edge, ExtraInfo>) => (
  <Link
    to={Router.DevelopersWebhooksSubscriptionEventLog({
      projectId,
      projectEnv,
      webhookSubscriptionId,
      logId: id,
    })}
  />
);

const defaultFixedColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 340,
    id: "eventType",
    title: t("webhook.table.eventType"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { eventType } }) => <TextCell variant="medium" text={eventType} />,
  },
];

const defaultActiveColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 400,
    id: "eventId",
    title: t("webhook.table.eventId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { eventId } }) => (
      <CopyableTextCell
        text={eventId}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 250,
    id: "dateTime",
    title: t("webhook.table.createdAt"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { createdAt } }) => (
      <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
  {
    width: 120,
    id: "status",
    title: t("webhook.table.statusCode"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { statusCode } }) =>
      typeof statusCode === "number" && (
        <Cell>
          {statusCode !== 0 && statusCode < 300 ? (
            <Tag color="positive">{`${statusCode} OK`}</Tag>
          ) : (
            <Tag color="negative">{`${statusCode} NOK`}</Tag>
          )}
        </Cell>
      ),
  },
];

export const WebhookSubscriptionEventLogs = ({
  webhookSubscriptionId,
  webhookEventTypes,
  params,
}: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  // The following pattern matching is used to correctly type the values,
  // incorrect values are set as `undefined` (aka. unset).
  const filters = useMemo((): WebhookEventsFilters => {
    return {
      statusText: match(params.statusText)
        .with("Success", "Failure", identity)
        .otherwise(() => undefined),
      eventType: match(params.eventType)
        .with(P.string, identity)
        .otherwise(() => undefined),
      endDate: match(params.endDate)
        .with(P.string, identity)
        .otherwise(() => undefined),
      startDate: match(params.startDate)
        .with(P.string, identity)
        .otherwise(() => undefined),
      resourceId: match(params.resourceId)
        .with(P.string, identity)
        .otherwise(() => undefined),
      eventId: match(params.eventId)
        .with(P.string, identity)
        .otherwise(() => undefined),
    } as const;
  }, [params]);

  const hasFilters = Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(WebhookEventLogsDocument, {
    first: NUM_EVENT_LOGS_PER_PAGE,
    filters: { ...filters, webhookSubscriptionId },
  });

  const columns = useColumnChooser("Webhook>Events", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const extraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      webhookSubscriptionId,
      reexecuteQuery: reload,
    };
  }, [projectEnv, projectId, webhookSubscriptionId, reload]);

  return (
    <>
      <FiltersForm
        filters={filters}
        columns={columns}
        onChangeFilters={filters =>
          Router.replace("DevelopersWebhooksSubscriptionEventLogs", {
            projectId,
            projectEnv,
            webhookSubscriptionId,
            ...filters,
          })
        }
        onRefresh={reload}
        eventTypes={webhookEventTypes}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={NUM_EVENT_LOGS_PER_PAGE}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.webhookEventLogs}>
            {webhookEventlog =>
              isNullish(webhookEventlog) ? (
                <EmptyView
                  icon="clipboard-search-regular"
                  title={t("common.list.noResults")}
                  subtitle={t("common.list.noResultsSuggestion")}
                />
              ) : (
                <VirtualizedList
                  variant="default"
                  marginHorizontal={negativeSpacings[24]}
                  extraInfo={extraInfo}
                  keyExtractor={keyExtractor}
                  data={webhookEventlog.edges.map(item => item.node)}
                  stickedToStartColumns={columns.fixed}
                  columns={columns.active}
                  onEndReached={() => {
                    if (webhookEventlog.pageInfo.hasNextPage === true) {
                      setVariables({ after: webhookEventlog.pageInfo.endCursor ?? undefined });
                    }
                  }}
                  headerHeight={48}
                  rowHeight={48}
                  getRowLink={getRowLink}
                  loading={{
                    isLoading,
                    count: NUM_EVENT_LOGS_PER_PAGE,
                  }}
                  renderEmptyList={() =>
                    hasFilters ? (
                      <EmptyView
                        icon="clipboard-search-regular"
                        title={t("common.list.noResults")}
                        subtitle={t("common.list.noResultsSuggestion")}
                      />
                    ) : (
                      <EmptyView
                        icon="lake-inbox-empty"
                        title={t("webhook.tableEmpty.title")}
                        subtitle={t("webhook.tableEmpty.message")}
                      />
                    )
                  }
                />
              )
            }
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
