import { AsyncData } from "@swan-io/boxed";
import { emailCardExpiration } from "@swan-io/emails";
import { invariantColors, radii } from "@swan-io/lake/src/constants/design";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { StyleSheet } from "react-native";
import { default as swanLogoUrl } from "../assets/img/logo-swan.svg";
import { t } from "../utils/i18n";

const styles = StyleSheet.create({
  iframe: {
    border: 0,
    borderRadius: radii[6],
  },
});

type Props = {
  language: "de" | "en" | "es" | "fr" | "it" | "nl" | "pt" | "fi";
  partnerColor: string | null | undefined;
  projectLogo: string | null | undefined;
  projectName: string | undefined;
};

export const NotificationsEmailCardExpirationPreview = ({
  language,
  partnerColor,
  projectLogo,
  projectName,
}: Props) => {
  const [body, setBody] = useState<AsyncData<string>>(AsyncData.Loading());

  useEffect(() => {
    const date = dayjs().toISOString();
    emailCardExpiration(
      {
        partnerName: projectName ?? t("projectSettings.branding.defaultName"),
        date,
        supportLink: "#",
        language,
        cardExpirationDate: dayjs().add(6, "month").toISOString(),
        cardHolderFirstName: "Joe",
        cardHolderAddressName: projectName,
        cardHolderFullname: "Joe Doe",
        cardHolderAddress: "1 rue de la Paix 75002 Paris",
        cardName: "My card",
        cardMaskedNumber: "1234",
        partnerLogoUrl: projectLogo ?? swanLogoUrl,
        partnerColor: partnerColor ?? invariantColors.defaultAccentColor,
      },
      { pretty: false },
    ).then(({ body }) => setBody(AsyncData.Done(body)));
  }, [language, projectLogo, partnerColor, projectName]);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState(1);

  useEffect(() => {
    const internalId = setInterval(() => {
      if (iframeRef.current != null) {
        const iframeWindow = iframeRef.current.contentWindow;
        if (iframeWindow == null) {
          return;
        }
        const iframeDocument = iframeWindow.document;
        const table = iframeDocument.querySelector("table");
        if (table != null) {
          // 16 is because the body has a 8px margin
          setIframeHeight(table?.offsetHeight + 16);
        }
      }
    }, 100);
    return () => clearInterval(internalId);
  }, []);

  return (
    <iframe
      ref={iframeRef}
      title="Email preview"
      width="600"
      style={styles.iframe}
      height={String(iframeHeight)}
      srcDoc={body.getOr("")}
    />
  );
};
